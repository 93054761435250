import type {ModelAsCode} from '@elanco/model-as-code'
import {SELECTION} from './selection.mac'
import {
	OPTIONAL,
	QUESTION_ID,
	QUESTION_TEXT,
	REQUIRED,
	REQUIRED_OR_OPTIONAL,
	SELECTIONS,
} from './common-types'

export const SINGLE_SELECTION = 'single_selection'
export const RENDER_MODE = 'render_mode'
export const RENDER_MODE_DROP_DOWN = 'drop_down'
export const RENDER_MODE_RADIO_BUTTON = 'radio_button'
export const SELECTION_PROMPT = 'selection_prompt'

const SingleSelectionQuestionModel: ModelAsCode = (builder) => ({
	modelType: 'contentType',
	name: 'Campaign Question - Single Selection',
	codename: SINGLE_SELECTION,
	elements: [
		builder.guidelinesElement({
			codename: 'usage_guidance',
			type: 'guidelines',
			guidelines:
				'<p>This type of campaign question will allow the user to make a single selection. The question can be rendered as a drop down list or as a series of radio buttons.</p>' +
				'<h5>Usage</h5>' +
				'<ul><li>Question Text: This is the text prompt for the question.  It will be rendered above the drop down or the radio buttons.</li>' +
				'<li>Selections: At least 2 selections are required, and the options will be rendered in the order they appear.</li>' +
				'<li>Question Identifier: This string is used as the key to the reponses in SFMC. It can contain only alphanumerics, dashes, and underscores</li>' +
				'<li>Selection Prompt: This is optional and allows for overriding the default text to prompt the user to make a selection.</li>' +
				'</ul>',
		}),
		builder.multipleChoiceElement({
			mode: 'single',
			options: [
				{codename: RENDER_MODE_DROP_DOWN, name: 'Drop Down List'},
				{codename: RENDER_MODE_RADIO_BUTTON, name: 'Radio Buttons'},
			],
			name: 'Render Mode',
			guidelines: 'Select how the single selection list should render.',
			is_required: true,
			type: 'multiple_choice',
			codename: RENDER_MODE,
		}),
		builder.multipleChoiceElement({
			mode: 'single',
			options: [
				{codename: REQUIRED, name: 'Required'},
				{codename: OPTIONAL, name: 'Optional'},
			],
			name: 'Required or Optional',
			guidelines:
				'indicate if the question will be required or optional.  Required questions will keep the user from submitting the form until they have made a selection.',
			is_required: true,
			type: 'multiple_choice',
			codename: REQUIRED_OR_OPTIONAL,
			default: {global: {value: [{codename: OPTIONAL}]}},
		}),
		builder.richTextElement({
			name: 'Question Text',
			guidelines:
				'The text of the question, to be presented to the user.',
			is_required: true,
			type: 'rich_text',
			codename: QUESTION_TEXT,
		}),
		builder.textElement({
			name: 'Question Identifier',
			guidelines:
				'The identifier used to pass the response to the question in to the campaign',
			is_required: true,
			type: 'text',
			codename: QUESTION_ID,
			validation_regex: {
				regex: '^[A-Za-z0-9_\\-]+$',
				is_active: true,
				validation_message:
					'Question Identifier can contain only alphanumeric characters, dashes and underscores',
			},
		}),
		builder.textElement({
			name: 'Selection Prompt',
			guidelines:
				'The text to display to prompt the user to make the selection (e.g. the default selection in a drop down box)',
			is_required: false,
			type: 'text',
			codename: SELECTION_PROMPT,
		}),
		builder.linkedItemsElement({
			item_count_limit: {value: 2, condition: 'at_least'},
			allowed_content_types: [{codename: SELECTION}],
			name: 'Selections',
			guidelines:
				'These are the selections that will be presented to the user.',
			is_required: true,
			type: 'modular_content',
			codename: SELECTIONS,
		}),
		builder.snippetElement({
			snippet: {codename: 'authentication_gate_component'},
			type: 'snippet',
		}),
	],
})

export default SingleSelectionQuestionModel
