import {StickyBottomNav} from '@elanco/component-library-v2'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {ResponsiveLinkWithIconBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/responsive-link-with-icon'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export const StickyBottomNavBlock: Block = ({block, ...context}) => {
	const auth = useAuth()

	// If the user is authenticated and the stickyBottomNavAuthenticated is populated then use that otherwise use stickyBottomNav
	const stickyNavBlock =
		auth.authenticated &&
		context.globalConfig.elements.stickyBottomNavAuthenticated[0]
			? context.globalConfig.elements.stickyBottomNavAuthenticated
			: context.globalConfig.elements.stickyBottomNav

	return (
		<div data-kontent-element-codename={block.system.codename}>
			{stickyNavBlock.length > 0 ? (
				<StickyBottomNav>
					{/* We take the first four items of the array as that's the limit set by the content model */}
					{stickyNavBlock.slice(0, 4).map((link) => (
						<ResponsiveLinkWithIconBlock
							block={link}
							className="w-1/4"
							key={link.system.id}
							{...context}
						/>
					))}
				</StickyBottomNav>
			) : null}
		</div>
	)
}
