import {OnPageNav as CIOnPageNav} from '@elanco/component-library-v2'
import {useRouter} from 'next/router'
import type {IContentItem} from '@kontent-ai/delivery-sdk'
import type {ExtendedBlock, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {SecondaryHeaderConfigContentItem} from '@/_new-code/products/flexible-web-toolkit/components/header/models'
import {ClickToBuySingleProductBlock} from '../../../../where-to-buy/smart-commerce/blocks/ClickToBuySingleProduct'
import {ClickToBuyMultiProductBlock} from '../../../../where-to-buy/smart-commerce/blocks/ClickToBuyMultiProduct'
import {ReusableCTAButtonComponent} from '../../../blocks/cta-button'

interface SecondaryNavigationProps {
	secondaryHeaderConfig: Tersed<SecondaryHeaderConfigContentItem>
	pageConfigColor?: string
	className?: string
}

export const OnPageNavBlock: ExtendedBlock<
	IContentItem,
	SecondaryNavigationProps
> = ({secondaryHeaderConfig, pageConfigColor, className, ...context}) => {
	const {locale = '', push} = useRouter()
	const imageAlt = secondaryHeaderConfig.elements.image[0]?.description
	return (
		<header
			className="max-h-3/4 transition-out fixed w-full overflow-y-auto bg-white md:static md:py-2"
			data-kontent-item-id={secondaryHeaderConfig.system.id}
			data-secondary-header
		>
			<div className="container-wide md:flex md:items-center md:justify-between">
				<CIOnPageNav
					brandColor={pageConfigColor}
					className={className ?? ''}
					data-kontent-element-codename="on_page_nav"
					locale={locale}
					logoAlt={imageAlt}
					logoClick={() =>
						push(
							secondaryHeaderConfig.elements.imageClickUrl || '#'
						)
					}
					logoSrc={secondaryHeaderConfig.elements.image[0]?.url}
					logoUrl={
						secondaryHeaderConfig.elements.imageClickUrl || '#'
					}
					navItems={secondaryHeaderConfig.elements.links.map((l) => ({
						id: l.elements.url,
						title: l.elements.text,
					}))}
				>
					<div className="flex flex-shrink flex-row gap-[5px] md:flex-shrink-0 md:items-center lg:gap-0">
						{secondaryHeaderConfig.elements.buttons.map(
							(button) => (
								<ReusableCTAButtonComponent
									{...context}
									block={button}
									className="mx-1 my-2 flex !px-6 !py-2 text-sm md:my-0 md:ml-4 md:flex-shrink-0"
									key={button.system.id}
									variant={pageConfigColor}
								/>
							)
						)}
						{secondaryHeaderConfig.elements
							.clickToBuySingleProduct[0] ? (
							<ClickToBuySingleProductBlock
								{...context}
								block={
									secondaryHeaderConfig.elements
										.clickToBuySingleProduct[0]
								}
							/>
						) : null}
						{secondaryHeaderConfig.elements
							.multiProductClickToBuy[0] ? (
							<ClickToBuyMultiProductBlock
								{...context}
								block={
									secondaryHeaderConfig.elements
										.multiProductClickToBuy[0]
								}
							/>
						) : null}
					</div>
				</CIOnPageNav>
			</div>
		</header>
	)
}
