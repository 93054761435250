import {useRouter} from 'next/router'
import {useRef} from 'react'
import type {CampaignData} from '@elanco/gateway-utils'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {sendScreenSetEventToDataLayer} from '@/utils/analytics'
import type {
	Block,
	Tersed,
	CampaignConfigContentITem,
} from '@/_new-code/services/kontent-ai/types'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {CdcGigyaContentItem} from '../../auth/cdc-screensets/basic'
import {CdcGigyaBlock} from '../../auth/cdc-screensets/basic'
import type {
	OnSubmitEvent,
	OnBeforeValidationEvent,
	OnBeforeSubmitEvent,
} from '../../auth/cdc-screensets/models'
import {setErrorLabel, FormSubmitErrorLabel} from './form-elements'
import {CDCScreenSetValidator} from './cdc-screenset-validator'
import {getBusinessData} from './business-search'
import {handleUTMQueryItem} from './cdc-screenset-helper'
import {CampaignInterestHandler} from './campaign-questions/campaign-interest-handler'
import {injectIntoScreenset} from './campaign-questions/campaign-question-injector'
import type {
	SingleSelectionContentItem,
	MultipleSelectionContentItem,
	DateSelectionContentItem,
} from './models'

export type CdcScreenSetWrapperContentItem = IContentItem<{
	wrappedScreenset: Elements.LinkedItemsElement<CdcGigyaContentItem>
	redirectPathOverride: Elements.TextElement
	campaignQuestions: Elements.LinkedItemsElement<
		| SingleSelectionContentItem
		| MultipleSelectionContentItem
		| DateSelectionContentItem
	>
	tabTitle: Elements.TextElement
	bsNoResultText: Elements.TextElement
	formSubmissionGenericErrorMessage: Elements.TextElement
	incompleteFormMessageTextWhileMoveAwayFromPage: Elements.TextElement
}>

export const CdcScreenSetWrapperBlock: Block<
	CdcScreenSetWrapperContentItem
> = ({
	block: {
		elements: {
			bsNoResultText,
			campaignQuestions,
			redirectPathOverride,
			formSubmissionGenericErrorMessage,
			wrappedScreenset: [wrappedScreenset],
		},
		system: {id, type},
	},
	page,
	...context
}) => {
	const router = useRouter()
	const {query, locale = ''} = router
	const screensetValidator = new CDCScreenSetValidator()
	const campaignInterestHandler = new CampaignInterestHandler()
	const containsCampaignQuestions = campaignQuestions.length !== 0
	const cdcScreenSetHandlerRef = useRef(null as HTMLDivElement | null)
	const holdRedirectUntilInterestApiFinished = campaignQuestions.length !== 0

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type -- Implicit is better here
	const onSubmit = (event: OnSubmitEvent) => {
		const businessSearchData = getBusinessData()
		const business = businessSearchData ?? event.formModel.data.business

		const data = {
			...event.formModel.data,
			business,
		}

		// Injected elements must be removed from profile object or CDC will error
		const injectedElements = campaignInterestHandler.getInjectedElements()
		const profile = containsCampaignQuestions
			? Object.fromEntries(
					Object.entries(event.formModel.profile).filter(
						([key, _value]) => {
							return !injectedElements.includes(key)
						}
					)
				)
			: event.formModel.profile

		return {...event.formModel, profile, data}
	}

	const onBeforeValidation = (_event: OnBeforeValidationEvent): void => {
		if (containsCampaignQuestions) {
			campaignInterestHandler.validate()
		}

		screensetValidator.validate()
	}

	const onAfterScreenLoad = (_event: OnAfterScreenLoadEvent): void => {
		screensetValidator.setup(locale, bsNoResultText)
		if (containsCampaignQuestions) {
			injectIntoScreenset(id, campaignQuestions, campaignInterestHandler)
		}
	}

	const onBeforeSubmit = (_event: OnBeforeSubmitEvent): boolean => {
		if (containsCampaignQuestions && !campaignInterestHandler.isValid()) {
			return false
		}

		return screensetValidator.isValid()
	}

	const onAfterSubmit = async (
		event: OnAfterSubmitEvent
	): Promise<boolean> => {
		const cdcScreenSetHandlerElement = cdcScreenSetHandlerRef.current
		if (cdcScreenSetHandlerElement) {
			const {top} = cdcScreenSetHandlerElement.getBoundingClientRect()
			window.scrollTo({
				top: window.scrollY + top - 270,
				behavior: 'smooth',
			})
		}
		const {UID} = event.response
		const {response, form} = event
		const medium = handleUTMQueryItem(query.utm_medium) ?? ''
		const source = handleUTMQueryItem(query.utm_source) ?? ''
		const pageConfig = page.elements.config[0]
		let campaignSubmissionResponse = true

		const campaignId =
			pageConfig && 'campaignId' in pageConfig.elements
				? (pageConfig as Tersed<CampaignConfigContentITem>).elements
						.campaignId
				: null

		const blockCodename = type

		if (campaignId) {
			const data: CampaignData = {
				ContactId: String(UID),
				campaignId,
				medium,
				source,
				data: {},
				typeOfCampaign: 'B2B',
			}

			campaignSubmissionResponse =
				await campaignInterestHandler.send(data)

			if (!campaignSubmissionResponse) {
				setErrorLabel(
					FormSubmitErrorLabel(),
					formSubmissionGenericErrorMessage
				)
			} else if (redirectPathOverride) {
				void router.push(
					addLocalePrefix(redirectPathOverride, router.locale ?? '')
				)
			}

			if (form === 'gigya-subscribe-with-email-form') {
				sendScreenSetEventToDataLayer(
					response,
					form,
					blockCodename,
					campaignId
				)
			}
		}

		return campaignSubmissionResponse
	}

	const eventListeners = {
		onSubmit,
		onBeforeValidation,
		onAfterScreenLoad,
		onBeforeSubmit,
		onAfterSubmit,
	}

	const redirectPath = redirectPathOverride
		? redirectPathOverride
		: wrappedScreenset?.elements.redirectPath

	if (wrappedScreenset) {
		wrappedScreenset.elements.redirectPath = redirectPath ?? ''
	}

	return (
		<div ref={cdcScreenSetHandlerRef}>
			{wrappedScreenset ? (
				<>
					<CdcGigyaBlock
						block={wrappedScreenset}
						eventRegistration={eventListeners}
						holdRedirectUntilInterestApiFinished={
							holdRedirectUntilInterestApiFinished
						}
						page={page}
						{...context}
					/>
					<span
						className="campaign-form-submit-error-message"
						style={{
							color: '#ce4635',
							display: 'block',
							fontWeight: 700,
							fontSize: '13px',
							lineHeight: '15px',
							textAlign: 'center',
						}}
					/>
				</>
			) : null}
		</div>
	)
}
