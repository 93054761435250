function fieldSelector<T extends Element>(
	selector: string,
	parent: Element | Document = document
): T | null {
	return parent.querySelector<T>(selector)
}

function setErrorLabel(
	label: HTMLLabelElement | HTMLSpanElement | null,
	message: string
): void {
	if (label === null) return

	label.innerText = message

	if (message === '') {
		label.style.visibility = 'hidden'
		label.classList.remove('gigya-error-msg', 'gigya-error-msg-active')
	} else {
		label.style.visibility = 'visible'
		label.classList.add('gigya-error-msg', 'gigya-error-msg-active')
	}
}

function BusinessToggle(): HTMLInputElement | null {
	const element = fieldSelector<HTMLInputElement>('input[id^=gigya-checkbox')
	return element
}

function businessFormDataInput(elementName: string): HTMLInputElement | null {
	const element = fieldSelector<HTMLInputElement>(
		`input[name="data.business.${elementName}"][data-screenset-roles*="instance"]`
	)
	return element
}

function businessFormDataSelect(elementName: string): HTMLSelectElement | null {
	const element = fieldSelector<HTMLSelectElement>(
		`select[name="data.business.${elementName}"][data-screenset-roles*="instance"]`
	)
	return element
}

function getCRMFieldElement(): HTMLInputElement | null {
	return businessFormDataInput('crmId')
}

function getTypeFieldValue(): string | undefined {
	return businessFormDataSelect('type')?.value
}

function getNameFieldValue(): string | undefined {
	return businessFormDataInput('name')?.value
}

function getAddress1FieldValue(): string | undefined {
	return businessFormDataInput('address1')?.value
}

function getAddress2FieldValue(): string | undefined {
	return businessFormDataInput('address2')?.value
}

function getCityFieldValue(): string | undefined {
	return businessFormDataInput('city')?.value
}

function getStateFieldValue(): string | undefined {
	return businessFormDataSelect('state')?.value
}

function getZipFieldValue(): string | undefined {
	return businessFormDataInput('zip')?.value
}

function setCRMFieldValue(value: string): void {
	const element = businessFormDataInput('crmId')
	if (element) {
		element.value = value
	}
}

function setTypeFieldValue(selectedIndex: number): void {
	const element = businessFormDataSelect('type')
	if (element) {
		element.selectedIndex = selectedIndex
	}
}

function setNameFieldValue(value: string): void {
	const element = businessFormDataInput('name')
	if (element) {
		element.value = value
	}
}

function setAddress1FieldValue(value: string): void {
	const element = businessFormDataInput('address1')
	if (element) {
		element.value = value
	}
}

function setAddress2FieldValue(value: string): void {
	const element = businessFormDataInput('address2')
	if (element) {
		element.value = value
	}
}

function setCityFieldValue(value: string): void {
	const element = businessFormDataInput('city')
	if (element) {
		element.value = value
	}
}

function setStateFieldValue(selectedIndex: number): void {
	const element = businessFormDataSelect('state')
	if (element) {
		element.selectedIndex = selectedIndex
	}
}

function setZipFieldValue(value: string): void {
	const element = businessFormDataInput('zip')
	if (element) {
		element.value = value
	}
}

function BusinessResultLabel(): HTMLLabelElement | null {
	const element = fieldSelector<HTMLLabelElement>(
		'[data-screenset-roles*="instance"] .renderresult'
	)
	return element
}

function BusinessSearchField(): HTMLInputElement | null {
	const element = fieldSelector<HTMLInputElement>(
		'.autocomplete-sep input[id^=gigya-textbox]'
	)
	return element
}

function BusinessSearchErrorLabel(): HTMLLabelElement | null {
	const element = fieldSelector<HTMLLabelElement>(
		'.autocomplete-sep > span[data-screenset-roles*="instance"]'
	)
	return element
}

function EmailField(): HTMLInputElement | null {
	const fullRegSelector =
		'input[id^=gigya-loginID][name=email][data-screenset-roles*="instance"]'
	const liteRegSelector =
		'input[id^=gigya-textbox-email][name="profile.email"][data-screenset-roles*="instance"]'

	const element = fieldSelector<HTMLInputElement>(
		`${fullRegSelector}, ${liteRegSelector}`
	)
	return element
}

function ConfirmEmailField(): HTMLInputElement | null {
	const element = fieldSelector<HTMLInputElement>(
		'.confirmemail > input[id^=gigya-textbox][data-screenset-roles*="instance"]'
	)
	return element
}

function ConfirmEmailLabel(): HTMLLabelElement | null {
	const element = fieldSelector<HTMLLabelElement>(
		'.confirmemail > label[data-screenset-roles*="instance"]'
	)
	return element
}

function ConfirmEmailErrorLabel(): HTMLLabelElement | null {
	const element = fieldSelector<HTMLLabelElement>(
		'.confirmemail > span[data-screenset-roles*="instance"]'
	)
	return element
}

function FormSubmitErrorLabel(): HTMLSpanElement | null {
	const element = fieldSelector<HTMLSpanElement>(
		'.campaign-form-submit-error-message'
	)
	return element
}

export {
	BusinessToggle,
	getCRMFieldElement,
	getTypeFieldValue,
	getNameFieldValue,
	getAddress1FieldValue,
	getAddress2FieldValue,
	getCityFieldValue,
	getStateFieldValue,
	getZipFieldValue,
	setCRMFieldValue,
	setTypeFieldValue,
	setNameFieldValue,
	setAddress1FieldValue,
	setAddress2FieldValue,
	setCityFieldValue,
	setStateFieldValue,
	setZipFieldValue,
	BusinessResultLabel,
	BusinessSearchField,
	BusinessSearchErrorLabel,
	EmailField,
	ConfirmEmailField,
	ConfirmEmailLabel,
	ConfirmEmailErrorLabel,
	FormSubmitErrorLabel,
	setErrorLabel,
}
