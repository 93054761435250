import {useEffect, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useRouter} from 'next/router'
import {
	isProductAdvantageXD,
	isProductGalliprant,
	isProductSeresto,
} from '@/utils/YPAY/reminders'
import {convertDateToLocaleDatePicker} from '@/utils/YPAY/commonFunctions'
import {fetchPetProfiles} from '@/fetchers/fetchPetProfiles'
import type {Channel, Reminder} from '@/fetchers/fetchReminders'
import {fetchPetReminders} from '@/fetchers/fetchReminders'
import {fetchMarketingBrands} from '@/fetchers/fetchMarketingBrands'
import type {MarketingBrand, Pet} from '@/models/consumerEngagement/models'
import {
	createReminder,
	deleteReminder,
	editReminder,
} from '@/_new-code/products/your-pet-and-you/mutations/mutate-reminders'

const REMINDER_MIN_PAST_DAYS_SERESTO = 210
const REMINDER_MAX_FUTURE_DAYS_SERESTO = 30

const REMINDER_MAX_FUTURE_DAYS = 45
const REMINDER_MIN_PAST_DAYS = 7

function calculateDate(extraDays: number, future: boolean): string {
	const today = new Date()
	const newDays = future
		? Number(extraDays * 24 * 60 * 60 * 1000)
		: -(extraDays * 24 * 60 * 60 * 1000)
	const minDateDt = new Date(today.getTime() + newDays)
	const minValueYear = minDateDt.getFullYear()
	let minValueMonth: string | number = minDateDt.getMonth() + 1
	let minValueDate: string | number = minDateDt.getDate()
	minValueMonth =
		minValueMonth > 9 ? minValueMonth : `0${String(minValueMonth)}`
	minValueDate = minValueDate > 9 ? minValueDate : `0${String(minValueDate)}`
	return `${String(minValueYear)}-${String(minValueMonth)}-${String(
		minValueDate
	)}`
}

export interface FormData {
	id: string
	medication: string
	nextReminderDate: string
	remindersCommunicationPreference: Channel[]
	petId?: string
}

interface MedicationReminderFormHookResults {
	pets: Pet[]
	marketingbrands: MarketingBrand[]
	onPetChange: (id: string) => void
	minDate: string | false
	maxDate: string | false
	handleFormSubmit: (data: FormData) => Promise<void>
	isLoading: boolean
	displayModal: boolean
	defaultPet: string
	onMedicationChange: (newValue: string) => void
	isProductSeresto: boolean
	isProductGalliprant: boolean
	isProductAdvantageXD: boolean
	displayPhoneVerification: boolean
	showTermsModal: boolean
	closeTermsModal: () => void
	checkEmailChannel: boolean
	checkSmsChannel: boolean
	defaultMedication?: string
	defaultDate: string
	closeModal: () => void
	setDisplayPhoneVerification: (newValue: boolean) => void
	selectedPetName: string
	currentRemindersIds: string[]
	defaultValues: {remindersCommunicationPreference: []} | false
	onReminderDateChange: () => void
	showError: boolean
}

export function useMedicationReminderForm(
	textOptionText: string,
	pageLocale: string,
	routerPath: string,
	redirectUrl: string,
	defaultSelectedPet?: Pet
): MedicationReminderFormHookResults {
	const router = useRouter()
	const [displayPhoneVerification, setDisplayPhoneVerification] =
		useState(false)
	const [selectedPet, setSelectedPet] = useState<Pet>()
	const [selectedMedication, setSelectedMedication] = useState('')
	const [showTermsModal, setShowTermsModal] = useState(false)
	const [currentReminders, setCurrentReminders] = useState<Reminder[]>([])
	const isEditMode = Boolean(router.query.edit)
	const [shouldValidateDates, setShouldValidateDates] = useState(false)
	const [showError, setShowError] = useState(false)

	const queryClient = useQueryClient()

	const invalidateQueries = (): void => {
		void queryClient.invalidateQueries({queryKey: ['reminders']})
		void queryClient.invalidateQueries({queryKey: ['pets']})
		void queryClient.invalidateQueries([
			'remindersData',
			{id: selectedPet?.id},
		])
	}
	const createReminderMutation = useMutation({
		mutationFn: (data: Reminder) => createReminder(data, pageLocale),
		onSuccess: invalidateQueries,
	})
	const editReminderMutation = useMutation({
		mutationFn: (data: Reminder) => editReminder(data, pageLocale),
		onSuccess: invalidateQueries,
	})
	const deleteReminderMutation = useMutation({
		mutationFn: (reminderId: string) =>
			deleteReminder(reminderId, pageLocale),
		onSuccess: invalidateQueries,
	})

	const displayModal = Object.prototype.hasOwnProperty.call(
		router.query,
		'reminder'
	)

	const {isLoading: isLoadingPets, data: pets} = useQuery<Pet[], Error>({
		queryKey: ['pets'],
		queryFn: () => fetchPetProfiles(pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})
	const {isLoading: isLoadingBrands, data: marketingbrands} = useQuery<
		MarketingBrand[],
		Error
	>({
		queryKey: ['marketingBrands'],
		queryFn: () => fetchMarketingBrands(pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})
	const {isLoading: isLoadingReminders, data: reminders} = useQuery<
		Reminder[],
		Error
	>({
		queryKey: ['reminders'],
		queryFn: () => fetchPetReminders(pageLocale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
		select: (fetchedReminders) =>
			// Date will be invalid if too far in the past, so we update it to the current or next month
			fetchedReminders.map((reminder) => {
				const product = reminder.marketingBrand?.id
				if (
					(product && isProductSeresto(product)) ??
					(product && isProductGalliprant(product))
				) {
					return reminder
				}
				return {
					...reminder,
					startDate: reminder.recurringDates?.[0]
						? convertDateToLocaleDatePicker(
								new Date(reminder.recurringDates[0])
							)
						: '',
				}
			}),
	})

	const testCurrentReminder = reminders?.find(
		(reminder) => reminder.id === router.query.reminder
	)
	const testCurrentReminders = reminders?.filter(
		(reminder) =>
			reminder.pet?.id === testCurrentReminder?.pet?.id &&
			reminder.marketingBrand?.id ===
				testCurrentReminder?.marketingBrand?.id
	)

	const emailChannel = testCurrentReminders?.find(
		(rem) => rem.channel === 'email'
	)
	const smsChannel = testCurrentReminders?.find(
		(rem) => rem.channel === 'sms'
	)

	useEffect(() => {
		setShouldValidateDates(false)
		if (defaultSelectedPet) {
			setSelectedPet(defaultSelectedPet)
			return
		}
		if (!router.query.reminder || !pets) return
		const isReminderPetId = pets.find(
			(pet) => pet.id === router.query.reminder
		)
		if (isReminderPetId) {
			setShouldValidateDates(true)
			setSelectedPet(isReminderPetId)
			return
		}

		if (!reminders) return

		const currentReminder = reminders.find(
			(reminder) => reminder.id === router.query.reminder
		)
		if (!currentReminder?.id) return

		setCurrentReminders(
			reminders.filter(
				(reminder) =>
					reminder.pet?.id === currentReminder.pet?.id &&
					reminder.marketingBrand?.id ===
						currentReminder.marketingBrand?.id
			)
		)
		setSelectedPet(pets.find((pet) => pet.id === currentReminder.pet?.id))

		if (currentReminder.marketingBrand?.id)
			setSelectedMedication(currentReminder.marketingBrand.id)

		// Do date validation in the next batch of updates
		// to allow the form to be filled correctly
		setTimeout(() => {
			setShouldValidateDates(true)
		})
	}, [defaultSelectedPet, pets, reminders, router.query])

	useEffect(() => {
		if (defaultSelectedPet) {
			setSelectedPet(defaultSelectedPet)
		}
	}, [defaultSelectedPet])

	const filteredMarketingBrands =
		marketingbrands && selectedPet
			? marketingbrands.filter(
					(product) =>
						product.species.toLowerCase() ===
						selectedPet.species.toLowerCase()
				)
			: marketingbrands

	const onPetChange = (petId: string): void => {
		const newSelectedPet = pets?.find((pet) => pet.id === petId)
		setSelectedPet(newSelectedPet)
	}

	// min dates

	const minDate = calculateDate(REMINDER_MIN_PAST_DAYS, false)
	const maxDate = calculateDate(REMINDER_MAX_FUTURE_DAYS, true)

	const minDateSeresto = calculateDate(REMINDER_MIN_PAST_DAYS_SERESTO, false)
	const maxDateSeresto = calculateDate(REMINDER_MAX_FUTURE_DAYS_SERESTO, true)

	function onAfterPhoneFormLoad(): void {
		const termsLink = document.getElementById('terms-modal-btn')
		if (!termsLink) {
			return
		}
		termsLink.addEventListener('click', (e) => {
			e.preventDefault()
			setShowTermsModal(true)
		})
	}

	function phoneScreenLoad(reminderData: FormData): void {
		gigya?.accounts.showScreenSet({
			screenSet: 'Loyalty-Web-Portal-RegistrationLoginV2',
			startScreen: 'loyalty-phone-required',
			containerID: 'phone-form-test',
			onAfterSubmit: () => {
				setDisplayPhoneVerification(false)

				void handleFormSubmit(reminderData, true)
			},
			onAfterScreenLoad: onAfterPhoneFormLoad,
		})
	}

	function closeModal(): void {
		setShouldValidateDates(false)
		// Close reminder modal by removing query parameter
		const isOnModalMode = Object.prototype.hasOwnProperty.call(
			router.query,
			'reminder'
		)

		if (isOnModalMode) {
			const params = new URLSearchParams(routerPath.split('?')[1])
			params.delete('reminder')
			params.delete('edit')
			const newRouterPath = routerPath.split('?')[0]
			void router.replace(
				{
					pathname: `/${pageLocale}${newRouterPath ?? ''}`,
					query: Object.fromEntries(params),
				},
				undefined,
				{shallow: true}
			)
		} else {
			void router.push(`/${pageLocale}${redirectUrl}`)
		}
		setSelectedPet(defaultSelectedPet)
		setSelectedMedication('')
	}

	async function handleFormSubmit(
		data: FormData,
		ignorePhoneCheck = false
	): Promise<void> {
		setShowError(false)
		if (data.nextReminderDate === '') {
			data.nextReminderDate = new Date().toISOString().slice(0, 10)
		}
		try {
			if (!data.petId && selectedPet && defaultSelectedPet) {
				data.petId = defaultSelectedPet.id
			}
			const isSmsChecked =
				data.remindersCommunicationPreference.includes(textOptionText)
			if (isSmsChecked && !ignorePhoneCheck) {
				setDisplayPhoneVerification(true)
				setTimeout(() => {
					phoneScreenLoad(data)
				}, 2000)
				return
			}

			if (!isEditMode && selectedPet) {
				await Promise.all(
					data.remindersCommunicationPreference.map((channel) =>
						createReminderMutation.mutateAsync({
							petId: selectedPet.id,
							productId: data.medication,
							startDate: data.nextReminderDate,
							dosesRemaining: 0, // hardcoded as indicated in the ACs of the ticket
							channel,
							cdcPetOwnerId: selectedPet.cdcPetOwnerId,
							id: data.id,
							isDeleted: false,
							lastModified: new Date(Date.now()).toUTCString(),
							status: 'active',
							date: '',
							serestoRefillDate: [],
						})
					)
				)
				closeModal()
				return
			}

			// TODO: Change hardcoded
			const newEmailChannel =
				data.remindersCommunicationPreference.includes('email')
			const newSmsChannel =
				data.remindersCommunicationPreference.includes(textOptionText)

			if (emailChannel?.id) {
				// email reminder exists
				if (newEmailChannel) {
					// email reminder update
					await editReminderMutation.mutateAsync({
						...emailChannel,
						id: emailChannel.id,
						petId: emailChannel.pet?.id,
						productId: emailChannel.marketingBrand?.id,
						startDate: data.nextReminderDate,
						dosesRemaining: 0,
						channel: emailChannel.channel,
					})
				} else {
					await deleteReminderMutation.mutateAsync(emailChannel.id)
				}
			} else if (newEmailChannel && currentReminders[0]) {
				// email reminder doesnt exist but needs creating
				void createReminderMutation.mutateAsync({
					petId: currentReminders[0].pet?.id,
					productId: currentReminders[0].marketingBrand?.id,
					startDate: data.nextReminderDate,
					dosesRemaining: 0,
					channel: 'email',
					id: currentReminders[0].id,
					cdcPetOwnerId: currentReminders[0].cdcPetOwnerId,
					isDeleted: currentReminders[0].isDeleted,
					lastModified: currentReminders[0].lastModified,
					status: currentReminders[0].status,
					date: '',
					serestoRefillDate: [],
				})
			}

			if (smsChannel?.id) {
				// sms reminder exists
				if (newSmsChannel) {
					// sms reminder update
					await editReminderMutation.mutateAsync({
						id: smsChannel.id,
						petId: smsChannel.pet?.id,
						productId: smsChannel.marketingBrand?.id,
						startDate: data.nextReminderDate,
						dosesRemaining: 0,
						channel: smsChannel.channel,
						cdcPetOwnerId: smsChannel.cdcPetOwnerId,
						isDeleted: smsChannel.isDeleted,
						lastModified: smsChannel.lastModified,
						status: smsChannel.status,
						date: '',
						serestoRefillDate: [],
					})
				} else {
					// sms reminder delete
					deleteReminderMutation.mutate(smsChannel.id)
				}
			} else if (newSmsChannel && currentReminders[0]) {
				// sms reminder doesn't exist but needs creating
				await createReminderMutation.mutateAsync({
					petId: currentReminders[0].pet?.id,
					productId: currentReminders[0].marketingBrand?.id,
					startDate: data.nextReminderDate,
					dosesRemaining: 0,
					channel: textOptionText as Channel,
					cdcPetOwnerId: currentReminders[0].cdcPetOwnerId,
					id: currentReminders[0].id,
					isDeleted: currentReminders[0].isDeleted,
					lastModified: currentReminders[0].lastModified,
					status: currentReminders[0].status,
					date: '',
					serestoRefillDate: [],
				})
			}

			closeModal()
		} catch (e) {
			setShowError(true)
		}
	}

	const isSeresto = isProductSeresto(selectedMedication)

	let defaultPet = ''
	const currentReminder = reminders?.find(
		(reminder) => reminder.id === router.query.reminder
	)

	if (currentReminder?.id) {
		defaultPet =
			pets?.find((pet) => pet.id === currentReminder.pet?.id)?.id ?? ''
	}

	let defaultDate = ''
	if (currentReminder?.recurringDates?.[0]) {
		defaultDate = convertDateToLocaleDatePicker(currentReminder.startDate)
	}

	return {
		pets: pets ?? [],
		marketingbrands: filteredMarketingBrands ?? [],
		onPetChange,
		minDate: shouldValidateDates && (isSeresto ? minDateSeresto : minDate),
		maxDate: shouldValidateDates && (isSeresto ? maxDateSeresto : maxDate),
		handleFormSubmit,
		isLoading:
			isLoadingPets ||
			isLoadingReminders ||
			createReminderMutation.isLoading ||
			editReminderMutation.isLoading ||
			deleteReminderMutation.isLoading ||
			isLoadingBrands,
		displayModal,
		defaultPet:
			(defaultPet || selectedPet?.id) ??
			router.query.reminder?.toString() ??
			'',
		onMedicationChange: (val) => {
			setSelectedMedication(val)
			setShouldValidateDates(true)
		},
		isProductSeresto: isSeresto,
		displayPhoneVerification,
		showTermsModal,
		closeTermsModal: () => {
			setShowTermsModal(false)
		},
		checkSmsChannel: smsChannel !== undefined,
		checkEmailChannel: emailChannel !== undefined,
		defaultMedication: currentReminder?.marketingBrand?.id,
		defaultDate,
		closeModal,
		setDisplayPhoneVerification,
		isProductGalliprant: isProductGalliprant(selectedMedication),
		isProductAdvantageXD: isProductAdvantageXD(selectedMedication),
		selectedPetName: selectedPet?.name ?? 'Your Pet',
		currentRemindersIds:
			testCurrentReminders
				?.map((reminder) => reminder.id)
				.filter(Boolean) ?? [],
		defaultValues: !isEditMode && {
			remindersCommunicationPreference: [],
		},
		onReminderDateChange: () => {
			setShouldValidateDates(true)
		},
		showError,
	}
}
