import {Button} from '@elanco/component-library-v2'
import {useInfiniteQuery} from 'react-query'
import {useRouter} from 'next/router'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useState} from 'react'
import {SkeletonLoader} from '@/_new-code/products/flexible-web-toolkit/components/skeleton-loader'
import {pushToDataLayer} from '@/utils/analytics'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import {fetchListerItems} from '@/fetchers/fetchListerItems'
import type {ListerItemsQueryKey} from '@/_new-code/products/flexible-web-toolkit/blocks/module-coupon-lister/types'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {
	ResourceCardBlock,
	type ResourceCardContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/resource-card'

export type ResourceCardListerContentItem = IContentItem<{
	initialItemsNumber: Elements.NumberElement
	subsequentItemsNumber: Elements.NumberElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	paginationString: Elements.TextElement
	buttonText: Elements.TextElement
	nextButtonText: Elements.TextElement
	prevButtonText: Elements.TextElement
	itemsOrderingOptions: Elements.MultipleChoiceElement
}>

export const ResourceCardListerBlock: Block<ResourceCardListerContentItem> = ({
	block,
	page,
	globalConfig,
	...context
}) => {
	const router = useRouter()
	const authState = useAuth()

	const [pageNumber, setPageNumber] = useState(0)

	const {buttonText} = block.elements

	const subsequentArticlesNumber = block.elements.subsequentItemsNumber ?? 6

	const initialItemsNumber = block.elements.initialItemsNumber ?? 6

	const {isLoading, isFetching, fetchNextPage, data} = useInfiniteQuery({
		queryKey: [
			'resourceLister',
			{
				codename: 'resource_card',
				locale: router.locale ?? '',
				orderBy:
					block.elements.itemsOrderingOptions[0]?.codename ??
					undefined,
				hideElementCodename: 'hide_from_lister',
				skip: 0,
				limit: initialItemsNumber,
			},
		] as ListerItemsQueryKey,
		queryFn: (opts) =>
			fetchListerItems<Tersed<ResourceCardContentItem>>(opts),
		getNextPageParam: () => {
			return pageNumber
		},
		staleTime: 10 * (60 * 1000),
		cacheTime: 15 * (60 * 1000),
		enabled: globalConfig.elements.cdcKey
			? (authState.ready && !authState.authenticated) ||
				(authState.ready &&
					authState.authenticated &&
					Boolean(authState.userDetails.id))
			: true,
	})

	const totalCount = data?.pages[0]?.pagination.totalCount ?? 0

	function getDisplayedItemCount(): number {
		const rawTotalItems =
			(data?.pages[0]?.pagination.count ?? 0) +
			pageNumber * initialItemsNumber

		return rawTotalItems > totalCount ? totalCount : rawTotalItems
	}

	function getHasNextPage(): boolean {
		const displayedItems = getDisplayedItemCount()

		return displayedItems < totalCount
	}

	return (
		<div>
			{isFetching || !data ? (
				<div className="mx-auto my-2 grid max-w-screen-xl grid-cols-1 gap-10 px-3 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
					<SkeletonLoader
						amount={
							!data
								? initialItemsNumber
								: subsequentArticlesNumber
						}
					/>
				</div>
			) : null}
			{!isLoading && data?.pages[0] ? (
				<div className="mx-auto flex max-w-screen-xl flex-wrap">
					<p
						className="mb-4 px-4 text-center font-bold sm:text-left"
						data-kontent-element-codename="pagination_string"
					>
						{block.elements.paginationString
							.replaceAll(
								'{{X}}',
								getDisplayedItemCount().toString()
							)
							.replaceAll('{{Y}}', totalCount.toString())}
					</p>
				</div>
			) : null}
			{!isLoading && data ? (
				<div className="mx-auto flex max-w-screen-xl flex-wrap justify-center">
					{data.pages.map((pageData) => {
						const items = pageData.items

						return items
							.filter(authState.checkUserRoleForBlock)
							.map((item) => (
								<div
									className="my-2 flex w-full flex-wrap justify-center sm:w-1/2 md:w-1/3"
									key={item.system.id}
								>
									<ResourceCardBlock
										block={item}
										globalConfig={globalConfig}
										page={page}
										{...context}
									/>
								</div>
							))
					})}
				</div>
			) : null}
			{isFetching ? (
				<div className="mx-auto my-2 grid max-w-screen-xl grid-cols-1 gap-10 px-3 sm:grid-cols-2 sm:px-0 md:grid-cols-3">
					<SkeletonLoader
						amount={
							!data
								? initialItemsNumber
								: subsequentArticlesNumber
						}
					/>
				</div>
			) : null}
			{buttonText && getHasNextPage() ? (
				<div
					className="my-10 text-center"
					data-kontent-element-codename="button_text"
				>
					<Button
						onClick={async () => {
							await fetchNextPage()
							setPageNumber((cur) => cur + 1)
							pushToDataLayer({
								event: 'cta_click',
								cta_name: buttonText,
								cta_category: 'button',
							})
						}}
						type="button"
					>
						{buttonText}
					</Button>
				</div>
			) : null}
		</div>
	)
}
