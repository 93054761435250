import {useEffect, useState} from 'react'
import {
	ParasiteSidepanel as Sidebar,
	Tabs as FormTabs,
	Tab as FormTab,
} from '@elanco/component-library-v2'
import type {
	ElementModels,
	Elements,
	IContentItem,
} from '@kontent-ai/delivery-sdk'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {LinkContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import type {ExtendedBlock} from '@/_new-code/services/kontent-ai/types'
import type {FormSelectorContentItem} from '../../form-selector'
import {FormSelectorBlock} from '../../form-selector'

export type ParasiteTrackerSidebarContentItem = IContentItem<{
	title: Elements.TextElement
	modalCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	themeHexcode: Elements.TextElement
	secondaryCta: Elements.LinkedItemsElement<CtaButtonContentItem>
	textLink: Elements.LinkedItemsElement<LinkContentItem>
	reportedText1: Elements.TextElement
	reportedText2: Elements.TextElement
	defaultScoreText: Elements.TextElement
	noScoreText: Elements.TextElement
	lowScoreText: Elements.TextElement
	mediumScoreText: Elements.TextElement
	highScoreText: Elements.TextElement
	defaultSummaryText: Elements.RichTextElement
	noneSummaryText: Elements.RichTextElement
	lowSummaryText: Elements.RichTextElement
	mediumSummaryText: Elements.RichTextElement
	highSummaryText: Elements.RichTextElement
	noCasesSummaryText: Elements.RichTextElement
	casesSummaryText: Elements.RichTextElement
	submissionForm: Elements.LinkedItemsElement<FormSelectorContentItem>
	formTitle: Elements.TextElement
	formSubtitle: Elements.TextElement
}>

export const ParasiteTrackerSidebarBlock: ExtendedBlock<
	ParasiteTrackerSidebarContentItem,
	{
		parasiteType: ElementModels.MultipleChoiceOption[]
		searchedCases: unknown[] | null
	}
> = ({
	block: {
		elements: {
			casesSummaryText,
			defaultSummaryText,
			formSubtitle,
			formTitle,
			highScoreText,
			highSummaryText,
			lowScoreText,
			lowSummaryText,
			mediumScoreText,
			mediumSummaryText,
			modalCta,
			noCasesSummaryText,
			noScoreText,
			noneSummaryText,
			reportedText1,
			reportedText2,
			secondaryCta,
			submissionForm,
			textLink,
			themeHexcode,
			title,
		},
		system: {id},
	},
	searchedCases,
	parasiteType,
	...context
}) => {
	const [summaryText, setSummaryText] = useState<string | undefined>(
		defaultSummaryText.value
	)
	const [reportedCases, setReportedCases] = useState<string>('')

	useEffect(() => {
		if (!searchedCases) return

		const getScoreText = (numOfCases: number): string => {
			if (numOfCases >= 1 && numOfCases < 5) return lowScoreText || ''
			if (numOfCases >= 5 && numOfCases < 9) return mediumScoreText || ''
			if (numOfCases >= 9) return highScoreText || ''
			return noScoreText || ''
		}

		const getSummaryText = (numOfCases: number): string => {
			if (numOfCases >= 1 && numOfCases < 5)
				return lowSummaryText.value || ''
			if (numOfCases >= 5 && numOfCases < 9)
				return mediumSummaryText.value || ''
			if (numOfCases >= 9) return highSummaryText.value || ''
			return noneSummaryText.value || ''
		}

		const isBlowfly = parasiteType[0]?.codename === 'blowfly'
		if (!isBlowfly) {
			setReportedCases(searchedCases.length.toString())

			const lungwormSummary =
				searchedCases.length > 0
					? casesSummaryText.value
					: noCasesSummaryText.value
			setSummaryText(lungwormSummary)
			return
		}

		const scoreText = getScoreText(searchedCases.length)
		setSummaryText(getSummaryText(searchedCases.length))
		setReportedCases(scoreText)
	}, [
		casesSummaryText.value,
		highScoreText,
		highSummaryText.value,
		lowScoreText,
		lowSummaryText.value,
		mediumScoreText,
		mediumSummaryText.value,
		noCasesSummaryText.value,
		noScoreText,
		noneSummaryText.value,
		parasiteType,
		searchedCases,
	])

	return (
		<div data-kontent-item-id={id}>
			<Sidebar
				modalCta={
					// Hack as component library expects a different form of CTA Button
					modalCta[0]
						? {
								variant: {
									choice: modalCta[0].elements.variant[0]
										?.codename,
								},
								text: modalCta[0].elements.text,
							}
						: null
				}
				reportedCases={reportedCases}
				reportedTextAbove={reportedText1}
				reportedTextBelow={reportedText2}
				secondaryCta={
					// Hack as component library expects a different form of CTA Button
					secondaryCta[0]
						? {
								url: secondaryCta[0].elements.url,
								openLinkInNewTab: {
									boolean:
										secondaryCta[0].elements
											.openLinkInNewTab[0]?.codename ===
										'yes',
								},
								variant: {
									choice: secondaryCta[0].elements.variant[0]
										?.codename,
								},
								text: secondaryCta[0].elements.text,
							}
						: null
				}
				summaryText={summaryText ?? ''}
				textLink={
					// Hack as component library expects a different form of Link
					textLink[0]
						? {
								url: textLink[0].elements.url,
								openInNewTab:
									textLink[0].elements.openInNewTab[0]
										?.codename === 'yes',
								text: textLink[0].elements.text,
							}
						: null
				}
				theme={themeHexcode || '#2772ce'}
				title={title}
			>
				<>
					<h2
						className="mb-5 text-center text-2xl"
						data-kontent-element-codename="form_title"
					>
						{formTitle}
					</h2>
					<h3
						className="mb-5 text-center text-base"
						data-kontent-element-codename="form_subtitle"
					>
						{formSubtitle}
					</h3>
					{/* eslint-disable-next-line no-nested-ternary -- This is readable */}
					{submissionForm[0] ? (
						submissionForm.length > 1 ? (
							<FormTabs
								className="rjsf-form-tabs"
								name="ParasiteTrackerSidebar"
								tabClasses="rjsf-form-tab flex-grow flex-1"
							>
								{submissionForm.map((form) => (
									<FormTab
										hideCloseBtn
										id={form.system.codename}
										key={form.system.id}
										title={form.elements.tabTitle}
									>
										<FormSelectorBlock
											block={form}
											{...context}
										/>
									</FormTab>
								))}
							</FormTabs>
						) : (
							<FormSelectorBlock
								block={submissionForm[0]}
								{...context}
							/>
						)
					) : null}
				</>
			</Sidebar>
		</div>
	)
}
