import * as React from 'react'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {ProductPortfolio} from '@elanco/component-library-v2'
import Image from 'next/image'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {
	RichTextBlock,
	type RichTextContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-text'
import {IconListBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-list'
import type {IconListContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-list'
import {loadImageFromKontentAI} from '@/imageLoader'

export type ListSectionContentItem = IContentItem<{
	title: Elements.TextElement
	iconList: Elements.LinkedItemsElement<IconListContentItem>
}>

export type ProductPortfolioContentItem = IContentItem<{
	cardRichText: Elements.LinkedItemsElement<RichTextContentItem>
	headerImage: Elements.AssetsElement
	infoButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	listSection: Elements.LinkedItemsElement<ListSectionContentItem>
}>

export const ListSectionBlock: Block<ListSectionContentItem> = ({
	block,
	...context
}) => {
	return (
		<>
			<h4>{block.elements.title}</h4>
			<div className="-mt-8">
				{block.elements.iconList[0] ? (
					<IconListBlock
						block={block.elements.iconList[0]}
						{...context}
					/>
				) : null}
			</div>
		</>
	)
}

export const ProductPortfolioBlock: Block<ProductPortfolioContentItem> = ({
	block,
	...context
}) => {
	return (
		<div className="container-wide mx-auto mt-32">
			<ProductPortfolio>
				<ProductPortfolio.Content>
					{block.elements.cardRichText[0] ? (
						<RichTextBlock
							block={block.elements.cardRichText[0]}
							{...context}
						/>
					) : null}
				</ProductPortfolio.Content>
				{block.elements.infoButton[0] ? (
					<div className="px-8 py-4">
						<ReusableCTAButtonComponent
							block={block.elements.infoButton[0]}
							{...context}
						/>
					</div>
				) : null}
				<ProductPortfolio.Icons>
					<div className="relative content-center">
						{block.elements.headerImage[0] ? (
							<Image
								alt={
									block.elements.headerImage[0].description ||
									''
								}
								className="p-4"
								fill
								loader={loadImageFromKontentAI}
								src={block.elements.headerImage[0].url}
							/>
						) : (
							<div className="m-5 p-10" />
						)}
						{block.elements.listSection.length > 0
							? block.elements.listSection.map((li) => (
									<ListSectionBlock
										block={li}
										key={li.system.id}
										{...context}
									/>
								))
							: null}
					</div>
				</ProductPortfolio.Icons>
			</ProductPortfolio>
		</div>
	)
}
