import {Quiz} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {defaultMargins} from '@/_new-code/products/flexible-web-toolkit/styles'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '../cta-button'
import {RichTextRenderer} from '../../components/rich-text-renderer'

type QuizQuestionOptionContentItem = IContentItem<{
	text: Elements.TextElement
	correct: Elements.MultipleChoiceElement
}>

type QuizQuestionContentItem = IContentItem<{
	text: Elements.TextElement
	feedbackText: Elements.RichTextElement
	answerOptions: Elements.LinkedItemsElement<QuizQuestionOptionContentItem>
}>

export type QuizContentItem = IContentItem<{
	questions: Elements.LinkedItemsElement<QuizQuestionContentItem>
	passPercentage: Elements.NumberElement
	nextButtonText: Elements.TextElement
	previousButtonText: Elements.TextElement
	correctFeedbackTitle: Elements.TextElement
	wrongFeedbackTitle: Elements.TextElement
	resultText: Elements.RichTextElement
	resultButtons: Elements.LinkedItemsElement<CtaButtonContentItem>
	failedResultText: Elements.RichTextElement
	failedResultButtons: Elements.LinkedItemsElement<CtaButtonContentItem>
	shareNotes: Elements.TextElement
	facebookShareUrl: Elements.TextElement
	twitterShareUrl: Elements.TextElement
	linkedinShareUrl: Elements.TextElement
	hideCorrectAnswerCount: Elements.MultipleChoiceElement
	hideProgressBar: Elements.MultipleChoiceElement
}>

export const QuizBlock: Block<QuizContentItem> = ({block, ...context}) => {
	const questions =
		block.elements.questions.length > 0
			? block.elements.questions.map((question) => ({
					id: question.system.id,
					questionText: (
						<span
							data-kontent-element-codename="text"
							data-kontent-item-id={question.system.id}
						>
							{question.elements.text}
						</span>
					),
					options: question.elements.answerOptions.map((answer) => ({
						optionText: answer.elements.text,
						isCorrect: answer.elements.correct,
					})),
					feedback: (
						<RichTextRenderer
							data-kontent-element-codename="feedback_text"
							element={question.elements.feedbackText}
							{...context}
						/>
					),
				}))
			: undefined

	return (
		<div className={defaultMargins}>
			<Quiz
				className="container-wide"
				correctOptionFeedbackTitle={block.elements.correctFeedbackTitle}
				facebookShareUrl={block.elements.facebookShareUrl || undefined}
				failedResult={
					<>
						<RichTextRenderer
							data-kontent-element-codename="failed_result_text"
							element={block.elements.failedResultText}
							{...context}
						/>
						<div className="mt-4">
							{block.elements.failedResultButtons.map(
								(button) => (
									<ReusableCTAButtonComponent
										block={button}
										className="m-2"
										key={button.system.id}
										{...context}
									/>
								)
							)}
						</div>
					</>
				}
				hideProgressBar={
					block.elements.hideProgressBar[0]?.codename === 'yes'
				}
				linkedinShareUrl={block.elements.linkedinShareUrl || undefined}
				nextBtnText={block.elements.nextButtonText || undefined}
				passPercent={block.elements.passPercentage ?? 0}
				prevBtnText={block.elements.previousButtonText || undefined}
				questions={questions}
				result={
					<>
						<RichTextRenderer
							data-kontent-element-codename="result_text"
							element={block.elements.resultText}
							{...context}
						/>
						<div className="mt-4">
							{block.elements.resultButtons.map((button) => (
								<ReusableCTAButtonComponent
									block={button}
									className="m-2"
									key={button.system.id}
									{...context}
								/>
							))}
						</div>
					</>
				}
				shareNotes={
					block.elements.shareNotes.length > 0 ? (
						<span data-kontent-element-codename="share_notes">
							{block.elements.shareNotes}
						</span>
					) : null
				}
				showUserScore
				twitterShareUrl={block.elements.twitterShareUrl || undefined}
				userCorrectAnswersCountText={
					block.elements.hideCorrectAnswerCount[0]?.codename === 'yes'
						? ''
						: undefined
				}
				wrongOptionFeedbackTitle={block.elements.wrongFeedbackTitle}
			/>
		</div>
	)
}
