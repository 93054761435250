import {useEffect, useState} from 'react'
import {BuyOnlineLinks, CardImage} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {RichCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-card'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {ReusableCTAButtonComponent} from '../cta-button'

export type BuyOnlineLinksContentItem = IContentItem<{
	content: Elements.RichTextElement
	onlineLinks: Elements.LinkedItemsElement<RichCardContentItem>
	shuffleOnlineLinks: Elements.MultipleChoiceElement
}>

export const BuyOnlineLinksBlock: Block<BuyOnlineLinksContentItem> = ({
	block,
	...context
}) => {
	const [shuffleState, setShuffleState] = useState(false)

	useEffect(() => {
		setShuffleState(
			block.elements.shuffleOnlineLinks[0]?.codename === 'yes' || false
		)
	}, [block.elements.shuffleOnlineLinks])

	const cards = block.elements.onlineLinks.map((card) => ({
		...card,
	}))

	if (shuffleState) {
		cards.sort(() => Math.random() - 0.5)
	}

	const renderedCards = cards.map((card) => {
		const instanceId = Math.random().toString(36).substring(7) // Generate a unique identifier for this card
		const {image, button, title} = card.elements
		return {
			key: `${instanceId}_${title}`, // Use instanceId and title to generate unique keys for cards
			image: image[0] ? (
				<CardImage
					alt={image[0].description}
					imageUrl={button[0]?.elements.url}
					src={image[0].url}
				/>
			) : null,
			button: button[0] ? (
				<ReusableCTAButtonComponent
					block={{
						...button[0],
						elements: {
							...button[0].elements,
							icon: [
								{
									codename: 'external',
									name: 'External',
								},
							],
						},
					}}
					{...context}
				/>
			) : null,
		}
	})
	return (
		<BuyOnlineLinks cards={renderedCards}>
			<div data-kontent-element-codename="buy_online_links">
				<RichTextRenderer
					data-kontent-element-codename="content"
					element={block.elements.content}
					{...context}
				/>
			</div>
		</BuyOnlineLinks>
	)
}
