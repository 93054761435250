import type {SyntheticEvent} from 'react'
import {
	Card,
	CardImage,
	MetaBlock,
	CardFooter,
	TextButton,
	ButtonIcons,
} from '@elanco/component-library-v2'
import Link from 'next/link'
import {useRouter} from 'next/router'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import {pushToDataLayer} from '@/utils/analytics'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {MetaBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/meta-block'

export type ArticleListerBlockContentItem = IContentItem<{
	title: Elements.TextElement
	subtitle: Elements.TextElement
	url: Elements.TextElement
	openInNewTab: Elements.MultipleChoiceElement
	metaBlock: Elements.LinkedItemsElement<MetaBlockContentItem>
	image: Elements.AssetsElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	productLabelUrl: Elements.LinkedItemsElement<CtaButtonContentItem>
	snippetCategoryColorCategoryColor: Elements.MultipleChoiceElement
}>

export const ArticleListerBlockBlock: Block<ArticleListerBlockContentItem> = ({
	block,
	...context
}) => {
	const router = useRouter()
	const metaBlock = block.elements.metaBlock[0]
	const metaBlockImage = metaBlock?.elements.image[0]
	const metaBlockAlignment =
		metaBlock?.elements.horizontalAlignment[0]?.codename
	const categoryColor =
		block.elements.snippetCategoryColorCategoryColor[0]?.codename
	const {locale} = useRouter()

	const getBackgroundColor = (): string => {
		if (categoryColor && categoryColor !== 'none') {
			return `bg-category-${categoryColor}`
		}
		return ''
	}

	const isExternalLink =
		metaBlock?.elements.openInNewTab[0]?.codename === 'yes'
	const metaBlockHref = addLocalePrefix(
		metaBlock?.elements.url ?? '',
		locale ?? ''
	)
	const titleHref = addLocalePrefix(block.elements.url, locale ?? '')

	return (
		<Card
			className="article-card h-full"
			href={block.elements.url}
			isExternalLink={block.elements.openInNewTab[0]?.codename === 'yes'}
			metaBlock={
				metaBlock?.elements.title ? (
					<MetaBlock
						aligned={metaBlockAlignment ?? 'left'}
						bgColor={getBackgroundColor()}
						blur
						image={
							metaBlockImage ? (
								<Image
									alt={metaBlockImage.description || ''}
									fill
									loader={loadImageFromKontentAI}
									src={metaBlockImage.url}
								/>
							) : null
						}
						isExternalLink={
							metaBlock.elements.openInNewTab[0]?.codename ===
							'yes'
						}
						metaBlockHref={metaBlockHref}
					>
						{metaBlock.elements.title}
					</MetaBlock>
				) : null
			}
			subtitle={
				<span data-kontent-element-codename="subtitle">
					{block.elements.subtitle}
				</span>
			}
			title={
				// eslint-disable-next-line no-nested-ternary -- This is clean tbh
				block.elements.title ? (
					block.elements.url ? (
						<Link
							className="flex h-full flex-col"
							href={titleHref}
							prefetch={false}
							rel="noreferrer"
							target={isExternalLink ? '_blank' : '_self'}
							onClick={() => {
								pushToDataLayer({
									event: 'card_click',
									element_text: block.elements.title,
									link_url: titleHref,
								})
							}}
						>
							{}

							<span data-kontent-element-codename="title">
								{block.elements.title}
							</span>
						</Link>
					) : (
						<span data-kontent-element-codename="title">
							{block.elements.title}
						</span>
					)
				) : null
			}
		>
			{block.elements.image[0] ? (
				<CardImage
					alt={block.elements.image[0].description}
					className="h-44 object-cover"
					src={block.elements.image[0].url}
				/>
			) : null}

			<CardFooter>
				{block.elements.ctaButton.map((button, index, arr) => (
					<ReusableCTAButtonComponent
						block={button}
						className={clsx(
							'mb-2',
							arr.length === 1 ? 'w-full' : null,
							index < arr.length - 1 && arr.length !== 1
								? 'mr-4'
								: null
						)}
						key={button.system.id}
						{...context}
					/>
				))}
				{block.elements.productLabelUrl.map((button) => {
					const href = addLocalePrefix(
						button.elements.url,
						router.locale ?? ''
					)

					const icon = button.elements.icon[0]?.codename
					return (
						<div
							className="flex text-center"
							key={button.system.id}
						>
							<TextButton
								className="w-full text-center"
								href={href}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- TODO: fix component library types
								icon={icon ? ButtonIcons[icon] : null}
								onClick={async (e: SyntheticEvent) => {
									e.stopPropagation()
									await router.push(href)
								}}
								target={
									button.elements.openLinkInNewTab[0]
										?.codename === 'yes'
										? '_blank'
										: '_self'
								}
							>
								{button.elements.text}
							</TextButton>
						</div>
					)
				})}
			</CardFooter>
		</Card>
	)
}
