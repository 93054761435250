const DEVICE_ID_KEY = 'device_id'

/**
 * Set, and return, a persistent device ID value
 * @returns A device ID value that will persist between browser sessions, or null if one could not be set.
 */
export const useDeviceId = (): {deviceId: string} => {
	if (typeof window === 'undefined') return {deviceId: 'elanco_server'}

	const deviceId = localStorage.getItem(DEVICE_ID_KEY)

	// If user already has device id cookie, use it
	if (deviceId) {
		return {deviceId}
	}

	const newId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
		/[xy]/g,
		(c) => {
			// eslint-disable-next-line no-bitwise -- This is the ID generation code provided by Kurz
			const r = (Math.random() * 16) | 0
			// eslint-disable-next-line no-bitwise -- This is the ID generation code provided by Kurz
			const v = c === 'x' ? r : (r & 0x3) | 0x8
			return v.toString(16)
		}
	)

	localStorage.setItem(DEVICE_ID_KEY, newId)

	return {deviceId: newId}
}
