import {FilterSideSelector} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {addLocalePrefix} from '@/components/BlockMapper/utils'
import type {LinkContentItem} from '../link'
import type {NavItemLinkContentItem} from '../../components/header/models'

export type FilterSideSelectorContentItem = IContentItem<{
	heading: Elements.TextElement
	icon: Elements.AssetsElement
	subHeading: Elements.TextElement
	hideLinks: Elements.MultipleChoiceElement
	showMore: Elements.TextElement
	showLess: Elements.TextElement
	maxLinks: Elements.NumberElement
	links: Elements.LinkedItemsElement<LinkContentItem | NavItemLinkContentItem>
}>

export const FilterSideSelectorBlock: Block<FilterSideSelectorContentItem> = ({
	block,
}) => {
	const {locale = ''} = useRouter()
	const linksWithLocale = block.elements.links.map((link) => ({
		...link.elements,
		url: addLocalePrefix(link.elements.url, locale),
	}))

	return (
		<FilterSideSelector
			className="hidden md:block"
			data-kontent-element-codename="filter_side_selector"
			heading={block.elements.heading}
			imageAlt={block.elements.icon[0]?.description ?? ''}
			imageUrl={block.elements.icon[0]?.url}
			links={linksWithLocale}
			numOfLinks={block.elements.maxLinks}
			seeLessText={block.elements.showLess}
			seeMoreText={block.elements.showMore}
			subHeading={block.elements.subHeading}
			toggleLinks={block.elements.hideLinks[0]?.codename === 'yes'}
		/>
	)
}
