import {useState, useEffect, useCallback} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {useRouter} from 'next/router'
import {fetchPetBreeds} from '@/fetchers/fetchPetBreeds'
import type {Pet, SpeciesBreed} from '@/models/consumerEngagement/models'
import type {PetFormData} from '@/fetchers/fetchPetProfiles'
import {createPetProfile, fetchPetProfiles} from '@/fetchers/fetchPetProfiles'
import {fetchShelters, fetchVetClinics} from '@/fetchers/fetchVetClinics'
import type {MapEntity} from '@/services/vetApi'
import type {
	GlobalConfigContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'
import {updatePetProfile} from '@/_new-code/products/your-pet-and-you/mutations/update-pet-profile'
import type {ShelterContentItem, ClinicContentItem} from '../adoptwizard'

const PET_MAX_AGE = 20

interface Option {
	value: string
	label: string
	customerId?: string
	corporateName?: string
}

interface PetCreateFormProps {
	submitForm: (data: PetFormData) => Promise<void>
	maxDate: string
	minDate: string
	minAdoptionDate: string
	defaultAdoptionDate: string
	setMinAdoptionDate: (newValue: string) => void
	selectedSpecies: string
	setSelectedSpecies: (newValue: string) => void
	breeds: Option[]
	searchClinics: () => void
	searchShelters: () => void
	setClinicZipCode: (newValue: string) => void
	setShelterZipCode: (newValue: string) => void
	clinics: Option[]
	shelters: Option[]
	onAcquisitionTypeChange: (newValue: string) => void
	showVetClinic: boolean
	showShelterSearch: boolean
	showPetShelter: boolean
	isLoading: boolean
	isLoadingVetClinics: boolean
	isLoadingShelters: boolean
	isSubmitting: boolean
	onShelterChange: (newValue: string) => void
	createAdditionalPet: () => void
	formFieldValue: Record<string, string> | undefined
	isError: boolean
	isValidPetName: (petName: string) => boolean
	isApiError: boolean
}

export function usePetCreateForm(
	shelterText: string,
	onSubmit: (
		formData: PetFormData,
		rawData: Pet,
		addExtraPet: boolean
	) => void,
	globalConfig: Tersed<GlobalConfigContentItem>,
	shelterData: Tersed<ShelterContentItem>[] = [],
	petData?: Pet
): PetCreateFormProps {
	const [creatingNewPet, setCreatingNewPet] = useState(false)
	const [isError, setIsError] = useState(false)

	// Empty locale as the default because the JWT Service has a fallback locale set
	const {locale = ''} = useRouter()
	const queryClient = useQueryClient()
	const {mutateAsync: createPetMutate, isLoading: isSubmittingCreate} =
		useMutation({
			mutationFn: (data: PetFormData) => createPetProfile(data, locale),
			mutationKey: 'petProfile/create',
			onSuccess: () =>
				queryClient.invalidateQueries({queryKey: ['pets']}),
		})
	const {mutateAsync: editPetMutate, isLoading: isSubmittingEdit} =
		useMutation({
			mutationFn: (data: PetFormData) => updatePetProfile(data, locale),
			mutationKey: 'petProfile/update',
			onSuccess: () => {
				void queryClient.invalidateQueries({queryKey: ['pets']})
				void queryClient.invalidateQueries({
					queryKey: ['petData', {id: petData?.id}],
				})
			},
		})

	const {
		isLoading: isLoadingPets,
		data: pets = [],
		isError: petApiError,
	} = useQuery<Pet[], Error>({
		queryKey: ['pets'],
		queryFn: () => fetchPetProfiles(locale),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	const isValidPetName = (petName: string): boolean =>
		pets.every((p) => p.name !== petName)

	const [clinicZipCode, setClinicZipCode] = useState('')
	const [shelterZipCode, setShelterZipCode] = useState('')
	const [showShelterSearch, setShowShelterSearch] = useState(
		shelterData.length > 0
	)
	const [showPetShelter, setShowPetShelter] = useState(shelterData.length > 0)
	const [showVetClinic, setShowVetClinic] = useState(
		petData?.vetClinic?.name !== undefined
	)
	const [shelterClinics, setShelterClinics] = useState<
		Tersed<ClinicContentItem>[]
	>([])
	const [formFieldValue, setFormFieldValue] = useState<
		Record<string, string> | undefined
	>(undefined)

	const today = new Date()
	const year = today.getFullYear()
	let month: string | number = today.getMonth() + 1
	let date: string | number = today.getDate()
	month = month > 9 ? month : `0${String(month)}`
	date = date > 9 ? date : `0${String(date)}`
	const maxDate = `${String(year)}-${String(month)}-${String(date)}`

	const minDateDt = new Date(
		today.getTime() - PET_MAX_AGE * 365 * 24 * 60 * 60 * 1000
	)
	const minValueYear = minDateDt.getFullYear()
	let minValueMonth: string | number = minDateDt.getMonth() + 1
	let minValueDate: string | number = minDateDt.getDate()
	minValueMonth =
		minValueMonth > 9 ? minValueMonth : `0${String(minValueMonth)}`
	minValueDate = minValueDate > 9 ? minValueDate : `0${String(minValueDate)}`
	const minDate = `${String(minValueYear)}-${String(minValueMonth)}-${String(
		minValueDate
	)}`
	const [minAdoptionDate, setMinAdoptionDate] = useState(minDate)

	const defaultAdoptionDate = `${String(year)}-${String(month)}-${String(
		date
	)}`

	const [selectedSpecies, setSelectedSpecies] = useState(
		petData?.species ?? 'dog'
	)

	const {
		data: breeds,
		isLoading: isLoadingBreeds,
		isError: breedsApiError,
	} = useQuery<SpeciesBreed[], Error, Option[]>({
		queryKey: ['petBreeds', {species: selectedSpecies}],
		queryFn: () => fetchPetBreeds(selectedSpecies, locale),
		select: (data) =>
			data.map((breed) => ({
				value: breed.breedName,
				label: breed.breedName,
			})),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
	})

	const {
		data: clinics,
		isLoading: isLoadingVetClinics,
		refetch: refetchClinics,
	} = useQuery<MapEntity[], Error, Option[]>({
		queryKey: ['vetClinics', {zipCode: clinicZipCode}],
		queryFn: () => fetchVetClinics(clinicZipCode),
		select: (data) =>
			data.map((clinic) => ({
				value: clinic.entity.ELA_customer_id__c,
				label: clinic.entity.corporate_name__v,
				additionalText:
					clinic.entity.addresses__v[0]?.formatted_address__v,
				customerId: clinic.entity.ELA_customer_id__c,
				corporateName: clinic.entity.corporate_name__v,
			})),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
		enabled: false,
	})

	const {
		data: shelters,
		isLoading: isLoadingShelters,
		refetch: refetchShelters,
	} = useQuery<MapEntity[], Error, Option[]>({
		queryKey: ['shelters', {zipCode: shelterZipCode}],
		queryFn: () => fetchShelters(shelterZipCode),
		select: (data) =>
			data.map((clinic) => ({
				value: clinic.entity.ELA_customer_id__c,
				label: clinic.entity.corporate_name__v,
				additionalText:
					clinic.entity.addresses__v[0]?.formatted_address__v,
				customerId: clinic.entity.ELA_customer_id__c,
				corporateName: clinic.entity.corporate_name__v,
			})),
		staleTime: Infinity,
		cacheTime: 15 * (60 * 1000),
		enabled: false,
	})

	const submitForm = async (data: PetFormData): Promise<void> => {
		setIsError(false)
		try {
			const acquisitionDetail = petData?.acquisitionDetail
			const id = petData?.id
			const selectedShelter = shelterData.find(
				(x) => x.elements.crmId === data.petShelter
			)

			const getShelterName = (): string => {
				if (selectedShelter) return selectedShelter.elements.name

				const shelterName = shelters?.find(
					(x) => x.customerId === data.petShelter
				)?.corporateName

				return shelterName ?? acquisitionDetail?.name ?? ''
			}

			const getClinicName = (): string => {
				if (selectedShelter) {
					return (
						selectedShelter.elements.clinics.find(
							(clinic) => data.vetClinic === clinic.elements.crmId
						)?.elements.name ?? ''
					)
				}

				const clinicName = clinics?.find(
					(x) => x.customerId === data.vetClinic
				)?.corporateName

				if (clinicName) return clinicName

				return petData?.vetClinic?.name ?? ''
			}

			const shelterName = getShelterName()
			const clinicName = getClinicName()

			let imageReference = petData?.imageReference
			if (!imageReference) {
				const dogIcon =
					globalConfig.elements.defaultDogIcon[0]?.system.id
				const catIcon =
					globalConfig.elements.defaultCatIcon[0]?.system.id

				if (data.petSpecies === 'dog' && dogIcon) {
					imageReference = dogIcon
				} else if (catIcon) {
					imageReference = catIcon
				}
			}

			const isEditingPet = id
			const formData = {
				...data,
				shelterName,
				clinicName,
				imageReference: imageReference ?? '',
				...(isEditingPet ? {id} : {}),
			}

			const result = isEditingPet
				? await editPetMutate(formData)
				: await createPetMutate(formData)

			onSubmit(
				{
					id: result.id,
					...data,
					shelterName,
					clinicName,
				},
				result,
				creatingNewPet
			)

			setCreatingNewPet(false)
		} catch (e) {
			setIsError(true)
		}
	}

	function searchClinics(): void {
		void refetchClinics()
		setShowVetClinic(true)
	}

	function searchShelters(): void {
		void refetchShelters()
		setShowPetShelter(true)
	}

	const onAcquisitionTypeChange = useCallback(
		(newVal: string) => {
			if (newVal === shelterText) {
				setShowShelterSearch(true)
			} else {
				setShowShelterSearch(false)
			}
		},
		[shelterText]
	)

	useEffect(() => {
		if (petData) {
			if (petData.acquisitionDetail?.id) {
				setShowPetShelter(true)
			}
			onAcquisitionTypeChange(petData.acquisitionSource)
		}
	}, [onAcquisitionTypeChange, petData])

	function onShelterChange(id: string): void {
		const shelter = shelterData.find((x) => x.elements.crmId === id)
		setFormFieldValue({vetClinic: ''})
		if (shelter !== undefined) {
			setShelterClinics(shelter.elements.clinics)
		}
		if (shelterData.length > 0) {
			setShowVetClinic(true)
		}
	}

	const createAdditionalPet = (): void => {
		setCreatingNewPet(true)
	}
	return {
		submitForm,
		maxDate,
		minDate,
		minAdoptionDate,
		defaultAdoptionDate,
		setMinAdoptionDate,
		breeds: breeds ?? [],
		selectedSpecies,
		setSelectedSpecies,
		searchClinics,
		searchShelters,
		setClinicZipCode,
		clinics:
			shelterClinics.length > 0
				? shelterClinics.map((shelter) => ({
						value: shelter.elements.crmId,
						label: shelter.elements.name,
					}))
				: clinics ?? [],
		shelters:
			shelterData.length > 0
				? shelterData.map((shelter) => ({
						value: shelter.elements.crmId,
						label: shelter.elements.name,
					}))
				: shelters ?? [],
		onAcquisitionTypeChange,
		showVetClinic,
		showShelterSearch,
		showPetShelter,
		setShelterZipCode,
		isLoading: isLoadingBreeds || isLoadingPets,
		isLoadingVetClinics,
		isLoadingShelters,
		isSubmitting: isSubmittingCreate || isSubmittingEdit,
		onShelterChange,
		createAdditionalPet,
		formFieldValue,
		isError,
		isValidPetName,
		isApiError: petApiError || breedsApiError,
	}
}
