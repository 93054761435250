import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

export type MsFormsContentItem = IContentItem<{
	formId: Elements.TextElement
	formHeight: Elements.NumberElement
}>

export const MsFormsBlock: Block<MsFormsContentItem> = ({block}) => (
	<div
		className="container-narrow flex justify-center py-2"
		data-kontent-element-id={block.system.id}
	>
		{/* TODO: Consider removing deprecated properties */}
		{/* TODO: Consider adding title to the ContentModel so it can be dynamic */}
		{/* TODO: Stop using units in height and width props - not valid syntax */}
		<iframe
			allowFullScreen
			frameBorder="0"
			height={`${String(block.elements.formHeight ?? 800)}px`}
			marginHeight={0}
			marginWidth={0}
			src={`https://forms.office.com/Pages/ResponsePage.aspx?id=${block.elements.formId}`}
			style={{border: 'none', maxWidth: '100%', maxHeight: '100vh'}}
			title="Data collection for this campaign"
			width="100%"
		/>
	</div>
)
