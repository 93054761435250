import {
	ContentModuleWithBullets as ClContentModuleWithBullets,
	BulletsContentAndImage,
	RTE as RichTextElement,
	Bullets,
	LargeVisualBlock,
} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {BulletContentItem} from '../types'

export type ContentModuleWithBulletsContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	bulletTitle: Elements.TextElement
	bullets: Elements.LinkedItemsElement<BulletContentItem>
	smallImage: Elements.AssetsElement
	largeImage: Elements.AssetsElement
	secondTitle: Elements.TextElement
	secondDescription: Elements.TextElement
	thirdTitle: Elements.TextElement
	thirdDescription: Elements.TextElement
	tabTitle: Elements.TextElement
}>

export const ContentModuleWithBulletsBlock: Block<
	ContentModuleWithBulletsContentItem
> = ({
	block: {
		elements: {
			title,
			description,
			bulletTitle,
			bullets,
			smallImage,
			largeImage,
			secondTitle,
			secondDescription,
			thirdTitle,
			thirdDescription,
		},
	},
}) => {
	return (
		<ClContentModuleWithBullets>
			<RichTextElement className="mb-6">
				<h2>{title}</h2>
				<p>{description}</p>
			</RichTextElement>
			<BulletsContentAndImage>
				<RichTextElement>
					<h3>{bulletTitle}</h3>
					<Bullets>
						{bullets.map(({elements: {item}, system: {id}}) => (
							<li key={`${id}-${item}`}>{item}</li>
						))}
					</Bullets>
				</RichTextElement>
				<LargeVisualBlock
					image={{
						src: smallImage[0]?.url,
						alt: smallImage[0]?.description ?? '',
					}}
					largeImageUrl={largeImage[0]?.url}
				/>
			</BulletsContentAndImage>
			<RichTextElement className="mt-6">
				<h3>{secondTitle}</h3>
				<p>{secondDescription}</p>
			</RichTextElement>
			<RichTextElement className="mt-6">
				<h3>{thirdTitle}</h3>
				<p>{thirdDescription}</p>
			</RichTextElement>
		</ClContentModuleWithBullets>
	)
}
