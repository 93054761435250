import type {ReactNode} from 'react'
import {clsx} from 'clsx'
import {env} from '@/utils/env/client.mjs'
import type {
	Tersed,
	Block,
	AdvancedPageTemplateContentItem,
	GlobalConfigContentItem,
} from '@/_new-code/services/kontent-ai/types'
import type {RichTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-text'
import {RichTextBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-text'
import type {BlogHeadingContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/blog-heading'
import {BlogHeadingBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/blog-heading'
import type {AnchorContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/anchor'
import {AnchorBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/anchor'
import type {ArticleHeaderBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/article-header-block'
import {ArticleHeaderBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/article-header-block'
import type {ArticleListerBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister-block'
import {ArticleListerBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister-block'
import type {ArticleListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister'
import {ArticleListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {CtaButtonBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import type {ArticleListerBlockLargeContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister-block-large'
import {ArticleListerBlockLarge} from '@/_new-code/products/flexible-web-toolkit/blocks/article-lister-block-large'
import type {HeaderBlockCarouselContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/header-block-carousel'
import {HeaderBlockCarouselBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/header-block-carousel'
import type {CardListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/card-lister'
import {CardListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/card-lister'
import type {RowContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/row'
import {RowBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/row'
import type {TagSingleContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/tag-single'
import {TagSingleBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/tag-single'
import type {TagBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/tag-block'
import {TagBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/tag-block'
import type {ShareBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/share-block'
import {ShareBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/share-block'
import type {ColumnCardListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/column-card-lister'
import {ColumnCardListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/column-card-lister'
import type {BenefitsModuleContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/benefits-module'
import {BenefitsModuleBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/benefits-module'
import type {CTAButtonEmailContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button-email'
import {CTAButtonEmailBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button-email'
import type {LargeBenefitsBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/large-benefits-block/large'
import {LargeBenefitsBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/large-benefits-block/large'
import type {ArrowheadIframeContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/arrowhead-iframe'
import {ArrowheadIframeBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/arrowhead-iframe'
import type {TrustcodeWidgetContentItem} from '@/_new-code/products/seresto-trustcode'
import {TrustcodeWidgetBlock} from '@/_new-code/products/seresto-trustcode'
import type {ScrollingImagesContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/scrolling-images'
import {ScrollingImagesBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/scrolling-images'
import type {ShareBlockStaticContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/share-block-static'
import {ShareBlockStaticBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/share-block-static'
import type {ModuleBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/module-block'
import {ModuleBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/module-block'
import type {SmallBenefitsBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/small-benefits-block/small'
import {SmallBenefitsBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/small-benefits-block/small'
import type {SmallBenefitsModuleContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/small-benefits-module/small-module'
import {SmallBenefitsModuleBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/small-benefits-module/small-module'
import type {BrandCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/brand-card'
import {BrandTableCardBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/brand-card'
import type {RichCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-card'
import {RichCardBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-card'
import {
	BuyOnlineLinksBlock,
	type BuyOnlineLinksContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/buy-online-links'
import {
	CardBlock,
	type CardContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/card'
import {
	FullWidthCarouselBlock,
	type FullWidthCarouselContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/carousel'
import {
	CarouselCTABlockBlock,
	type CarouselCtaBlockContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/carousel-cta-block'
import {type ClickToBuyMultiProductContentItem} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToBuyMultiProduct/click-to-buy-multi-product'
import type {ClickToBuySingleProductContentItem} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToBuySingleProduct/click-to-buy-single-product'
import type {ClickToCartContentItem} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToCart'
import type {MikmakButtonContentItem} from '@/_new-code/products/where-to-buy/mikmak/mikmak-button'
import {ClickToBuyMultiProductBlock} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToBuyMultiProduct'
import {ClickToBuySingleProductBlock} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToBuySingleProduct'
import {ClickToCartBlock} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToCart'
import {MikMakButtonBlock} from '@/_new-code/products/where-to-buy/mikmak/mikmak-button'
import type {CouponContentItem} from '@/_new-code/products/your-pet-and-you/blocks/coupon'
import {CouponBlock} from '@/_new-code/products/your-pet-and-you/blocks/coupon'
import type {ComparisonTableContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/comparison-table'
import {ComparisonTableBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/comparison-table'
import type {ContentModuleWithBulletsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/with-bullets/content-module-with-bullets'
import {ContentModuleWithBulletsBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/with-bullets/content-module-with-bullets'
import type {ListerBlockWithBulletsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/with-bullets/lister-block-with-bullets'
import {ListerBlockWithBullets} from '@/_new-code/products/flexible-web-toolkit/blocks/with-bullets/lister-block-with-bullets'
import type {CountrySelectorContentItem} from '@/_new-code/products/country-selector'
import {CountrySelectorBlock} from '@/_new-code/products/country-selector'
import type {CouponIframeContentItem} from '@/_new-code/products/where-to-buy/revtrax/coupon-iframe'
import {CouponIframeBlock} from '@/_new-code/products/where-to-buy/revtrax'
import type {DistributorCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/distributor-card'
import {DistributorCardBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/distributor-card'
import type {DistributorCardListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/distributor-card-lister'
import {DistributorCardListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/distributor-card-lister'
import type {ImageContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/image'
import {ImageBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/image'
import type {EnhancedCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/enhanced-card'
import {EnhancedCardBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/enhanced-card'
import type {BreadcrumbsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/breadcrumbs'
import {BreadcrumbsBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/breadcrumbs'
import type {IconListContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-list'
import {IconListBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/icon-list'
import type {FactListerBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/fact-lister-block'
import {FactListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/fact-lister-block'
import type {WistiaContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/wistia'
import {WistiaBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/wistia'
import type {VideoBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/video-block'
import {VideoBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/videos/video-block'
import type {FindYourRepContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/find-your-rep'
import {FindYourRepBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/find-your-rep'
import type {FaqContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/faq'
import {FaqBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/faq'
import type {FullTabbedBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/full-tabbed-block'
import {FullTabbedBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/full-tabbed-block'
import type {GovernanceBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/governance-block'
import {GovernanceBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/governance-block'
import type {HeaderBlockWithCopyContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/header-block-with-copy'
import {HeaderBlockWithCopyBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/header-block-with-copy'
import type {HistoryTimelineContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/history-timeline'
import {HistoryTimelineBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/history-timeline'
import type {HomePageHeaderBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/home-page-header-block'
import {HomePageHeaderBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/home-page-header-block'
import type {HomeVideoBannerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/home-video-banner'
import {HomeVideoBannerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/home-video-banner'
import type {ImageGalleryContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/image-gallery'
import {ImageGalleryBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/image-gallery'
import type {JotFormContentItem} from '@/_new-code/products/campaign-forms/jotform'
import {JotFormBlock} from '@/_new-code/products/campaign-forms/jotform'
import type {StepsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/steps'
import {StepsBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/steps'
import type {MediaCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/media-card'
import {MediaCardBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/media-card'
import type {SmallTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/small-text'
import {SmallTextBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/small-text'
import type {YoutubeVideoHeaderBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/youtube-video-header-block'
import {YoutubeVideoHeaderBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/youtube-video-header-block'
import type {LinkContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import {LinkBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/link'
import type {SectionHeaderBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/section-header-block'
import {SectionHeaderBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/section-header-block'
import type {KeyInfoModuleColumnsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/key-info-module-columns'
import {KeyInfoModuleColumnsBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/key-info-module-columns'
import type {TableContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/table'
import {TableBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/table'
import type {RichTextWithCTAContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-text-with-cta'
import {RichTextWithCtaBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/rich-text-with-cta'
import type {KeyInfoModuleContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/key-info-module'
import {KeyInfoModuleBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/key-info-module'
import type {MsFormsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/ms-forms'
import {MsFormsBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/ms-forms'
import type {OfficeLocationContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/office-location'
import {OfficeLocationBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/office-location'
import type {ProductDirectoryCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/product-directory-card'
import {ProductDirectoryCardBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/product-directory-card'
import type {PetNameFormContentItem} from '@/_new-code/products/pet-name-finder/pet-name-form'
import {PetNameFormBlock} from '@/_new-code/products/pet-name-finder/pet-name-form'
import type {PetViewAllNamesContentItem} from '@/_new-code/products/pet-name-finder/pet-view-all-names'
import {PetViewAllNamesBlock} from '@/_new-code/products/pet-name-finder/pet-view-all-names'
import type {ProductSectionModContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/product-section-mod'
import {ProductSectionModBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/product-section-mod'
import type {PromoModuleContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/promo-module'
import {PromoModuleBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/promo-module'
import type {PromotionCardGroupContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/promotion-card-group'
import {PromotionCardGroupBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/promotion-card-group'
import type {QuizContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/quiz'
import {QuizBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/quiz'
import type {QuoteContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/quote'
import {QuoteBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/quote'
import type {QuoteSliderContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/quote-slider'
import {QuoteSliderBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/quote-slider'
import type {ResourceCardContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/resource-card'
import {ResourceCardBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/resource-card'
import type {ResponsiveLinkWithIconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/responsive-link-with-icon'
import {ResponsiveLinkWithIconBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/responsive-link-with-icon'
import type {PlayableWidgetContentItem} from '@/_new-code/products/playable'
import {PlayableWidgetBlock} from '@/_new-code/products/playable'
import type {StickyBarContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/sticky-bar'
import {StickyBarBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/sticky-bar'
import type {EprRebateStatusContentItem} from '@/_new-code/products/dvm-rebates/epr'
import {EprRebateStatusBlock} from '@/_new-code/products/dvm-rebates/epr'
import type {RebateHistoryContentItem} from '@/_new-code/products/dvm-rebates/rebate-history'
import {RebateHistoryBlock} from '@/_new-code/products/dvm-rebates/rebate-history'
import type {SearchHeaderBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/search-header-block'
import {SearchHeaderBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/search-header-block'
import type {SearchModuleContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/search-module'
import {SearchModuleBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/search-module'
import type {ShowSearchResultsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/show-search-results'
import {ShowSearchResultsBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/show-search-results'
import type {SecondaryHeaderContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/secondary-header'
import {SecondaryHeaderBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/secondary-header'
import type {SimpleContentBlockWithIconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/simple-content-block-with-icon'
import {SimpleContentBlockWithIconBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/simple-content-block-with-icon'
import type {RebatesTableAndFilterContentItem} from '@/_new-code/products/your-pet-and-you/blocks/rebates-table-and-filter'
import {RebatesTableAndFilterBlock} from '@/_new-code/products/your-pet-and-you/blocks/rebates-table-and-filter'
import type {RebatesBlockContentItem} from '@/_new-code/products/your-pet-and-you/blocks/rebates-block'
import {RebatesBlockBlock} from '@/_new-code/products/your-pet-and-you/blocks/rebates-block'
import type {SimpleListerBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/simple-lister-block'
import {SimpleListerBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/simple-lister-block'
import type {ModuleDashboardPetCardsContentItem} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-dashboard-pet-cards'
import {ModuleDashboardPetCardsBlock} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-dashboard-pet-cards'
import type {HeadingActionsWrapperContentItem} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/heading-actions-wrapper'
import {HeadingActionsWrapperBlock} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/heading-actions-wrapper'
import type {HeroSectionContentItem} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/hero-section'
import {HeroSectionBlock} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/hero-section'
import type {ModuleMyRemindersContentItem} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-my-reminders'
import {ModuleMyRemindersBlock} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-my-reminders'
import type {ModuleMedicationReminderModalContentItem} from '@/_new-code/products/your-pet-and-you/blocks/module-medication-reminder-modal'
import {ModuleMedicationReminderModalBlock} from '@/_new-code/products/your-pet-and-you/blocks/module-medication-reminder-modal'
import type {PetCreationWizardContentItem} from '@/_new-code/products/your-pet-and-you/blocks/pet-creation-wizard'
import {PetCreateWizardBlock} from '@/_new-code/products/your-pet-and-you/blocks/pet-creation-wizard'
import type {ModuleTableLayoutContentItem} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-table-layout'
import {ModuleTableLayoutBlock} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-table-layout'
import type {ProductDirectoryContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/product-directory'
import {ProductDirectoryBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/product-directory'
import type {TabbedBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/tabbed-block'
import {TabbedBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/tabbed-block'
import type {TabbedContentBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/tabbed-content-block'
import {TabbedContentBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/tabbed-content-block'
import type {VideoWithRichTextContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/video-with-rich-text'
import {VideoWithRichTextBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/video-with-rich-text'
import type {ParasiteTrackerModuleContentItem} from '@/_new-code/products/disease-map/parasite-tracker/parasite-tracker-module'
import {ParasiteTrackerModuleBlock} from '@/_new-code/products/disease-map/parasite-tracker/parasite-tracker-module'
import type {FilterSideSelectorContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/filter-side-selector'
import {FilterSideSelectorBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/filter-side-selector'
import type {FilterBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/filter-block'
import {FilterBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/filter-block'
import type {TileContainerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/tile-container'
import {TileContainerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/tile-container'
import type {TileWithIconContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/tile-with-icon'
import {TileWithIconBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/tile-with-icon'
import type {ModuleCdcTabBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/module-cdc-tab-block'
import {ModuleCdcTabBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/module-cdc-tab-block'
import type {ColumnContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/Column'
import {ColumnBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/Column'
import type {ModuleCouponListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/module-coupon-lister'
import {ModuleCouponListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/module-coupon-lister'
import type {FloatingComponentContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/floating-component/floating-component'
import {FloatingComponentBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/floating-component/floating-component'
import type {ParasiteSelectorCtaCarouselContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/parasite-selector-cta-carousel'
import {ParasiteSelectorCtaCarouselBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/parasite-selector-cta-carousel'
import type {InteractiveListContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/interactive-list'
import {InteractiveListBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/interactive-list'
import type {TableBlockWithIconsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/table-block-with-icons'
import {TableBlockWithIconsBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/table-block-with-icons'
import type {PetDynamicArticleListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/dynamic-article-lister'
import {DynamicArticleListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/dynamic-article-lister'
import type {FindABusinessContentItem} from '@/_new-code/products/find-a-business'
import {FindABusinessBlock} from '@/_new-code/products/find-a-business'
import type {FaqHeadingShareBlockContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/faq-heading-share-block'
import {FaqHeadingShareBlockBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/faq-heading-share-block'
import {
	CdcScreenSetWrapperBlock,
	type CdcScreenSetWrapperContentItem,
} from '@/_new-code/products/campaign-forms/cdc-screen-set-wrapper'
import type {FaqSearchHeaderContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/faq-search-header'
import {FaqSearchHeaderBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/faq-search-header'
import {
	EnhancedProductDirectoryCardBlock,
	type EnhancedProductDirectoryCardContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/enhanced-product-card'
import type {
	ListSectionContentItem,
	ProductPortfolioContentItem,
} from '@/_new-code/products/flexible-web-toolkit/blocks/product-portfolio'
import {
	ListSectionBlock,
	ProductPortfolioBlock,
} from '@/_new-code/products/flexible-web-toolkit/blocks/product-portfolio'
import {NadisWidgetBlock} from '@/_new-code/products/nadis-widget'
import type {FaqSearchResultsContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/faq-search-results'
import {FaqSearchResultsBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/faq-search-results'
import type {FeaturedArticleContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/featured-article'
import {FeaturedArticleBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/featured-article'
import type {ModulePetProfileCardContentItem} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-pet-profile-card.mac.ts'
import {ModulePetProfileCardBlock} from '@/_new-code/products/your-pet-and-you/blocks/dashboard-page/module-pet-profile-card.mac.ts'
import {
	AdoptWizardBlock,
	type AdoptWizardContentItem,
} from '@/_new-code/products/your-pet-and-you/blocks/adoptwizard'
import type {FilterBarContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/filter-bar'
import {FilterBarBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/filter-bar'
import type {CdcGigyaContentItem} from '@/_new-code/products/auth/cdc-screensets/basic'
import {CdcGigyaBlock} from '@/_new-code/products/auth/cdc-screensets/basic'
import type {CdcGigyaPopupContentItem} from '@/_new-code/products/auth/cdc-screensets/popup'
import {CdcGigyaPopupBlock} from '@/_new-code/products/auth/cdc-screensets/popup'
import type {ProductDirectoryListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/product-directory-lister'
import {ProductDirectoryListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/product-directory-lister'
import type {FormSelectorContentItem} from '@/_new-code/products/disease-map/form-selector'
import {FormSelectorBlock} from '@/_new-code/products/disease-map/form-selector'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import type {CurrentUserContext} from '@/models/CurrentUserContext'
import type {VetRebateNoCrmIdFoundContentItem} from '@/_new-code/products/vet-rebates/no-business-id-error'
import {VetRebateNoCrmIdFoundBlock} from '@/_new-code/products/vet-rebates/no-business-id-error'
import type {VetRebatePermissionContentItem} from '@/_new-code/products/vet-rebates/rebate-permission'
import {VetRebatePermissionBlock} from '@/_new-code/products/vet-rebates/rebate-permission'
import type {ModuleArticleListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/module-article-lister'
import {ModuleArticleListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/module-article-lister'
import type {ResourceCardListerContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/resource-card-lister'
import {ResourceCardListerBlock} from '@/_new-code/products/flexible-web-toolkit/blocks/listers/resource-card-lister'

// **Please keep keys sorted alphabetically**
export interface BlockMap {
	adoptwizard: Block<AdoptWizardContentItem>
	anchor: Block<AnchorContentItem>
	arrowhead_iframe: Block<ArrowheadIframeContentItem>
	article_header_block: Block<ArticleHeaderBlockContentItem>
	article_lister_block_large: Block<ArticleListerBlockLargeContentItem>
	article_lister_block: Block<ArticleListerBlockContentItem>
	article_lister: Block<ArticleListerContentItem>
	benefits_module: Block<BenefitsModuleContentItem>
	better_page_model: Block<RichTextContentItem>
	blog_heading: Block<BlogHeadingContentItem>
	brand_card: Block<BrandCardContentItem>
	breadcrumbs: Block<BreadcrumbsContentItem>
	buy_online_links: Block<BuyOnlineLinksContentItem>
	card_lister: Block<CardListerContentItem>
	card: Block<CardContentItem>
	carousel_cta_block: Block<CarouselCtaBlockContentItem>
	cdc_gigya_popup: Block<CdcGigyaPopupContentItem>
	cdc_gigya: Block<CdcGigyaContentItem>
	cdc_screen_set_wrapper: Block<CdcScreenSetWrapperContentItem>
	click_to_buy_multi_product: Block<ClickToBuyMultiProductContentItem>
	click_to_buy_single_product: Block<ClickToBuySingleProductContentItem>
	click_to_cart: Block<ClickToCartContentItem>
	column_card_lister: Block<ColumnCardListerContentItem>
	column: Block<ColumnContentItem>
	comparison_table: Block<ComparisonTableContentItem>
	content_module_with_bullets: Block<ContentModuleWithBulletsContentItem>
	country_selector: Block<CountrySelectorContentItem>
	coupon_iframe: Block<CouponIframeContentItem>
	coupon: Block<CouponContentItem>
	cta_button_email: Block<CTAButtonEmailContentItem>
	cta_button: Block<CtaButtonContentItem>
	distributor_card_lister: Block<DistributorCardListerContentItem>
	distributor_card: Block<DistributorCardContentItem>
	dynamic_article_lister: Block<PetDynamicArticleListerContentItem>
	enhanced_card: Block<EnhancedCardContentItem>
	enhanced_product_card: Block<EnhancedProductDirectoryCardContentItem>
	enhanced_product_directory_card: Block<EnhancedProductDirectoryCardContentItem>
	epr_rebate_status: Block<EprRebateStatusContentItem>
	fact_lister_block: Block<FactListerBlockContentItem>
	faq_heading_share_block: Block<FaqHeadingShareBlockContentItem>
	faq_search_header: Block<FaqSearchHeaderContentItem>
	faq_search_results: Block<FaqSearchResultsContentItem>
	faq: Block<FaqContentItem>
	featured_article: Block<FeaturedArticleContentItem>
	filter_bar: Block<FilterBarContentItem>
	filter_block: Block<FilterBlockContentItem>
	filter_side_selector: Block<FilterSideSelectorContentItem>
	find_a_business: Block<FindABusinessContentItem>
	find_your_rep: Block<FindYourRepContentItem>
	floating_component: Block<FloatingComponentContentItem>
	form_selector: Block<FormSelectorContentItem>
	full_tabbed_block: Block<FullTabbedBlockContentItem>
	full_width_carousel: Block<FullWidthCarouselContentItem>
	governance_block: Block<GovernanceBlockContentItem>
	header_block_carousel: Block<HeaderBlockCarouselContentItem>
	header_block_with_copy: Block<HeaderBlockWithCopyContentItem>
	heading_actions_wrapper: Block<HeadingActionsWrapperContentItem>
	hero_section: Block<HeroSectionContentItem>
	history_timeline: Block<HistoryTimelineContentItem>
	home_page_header_block: Block<HomePageHeaderBlockContentItem>
	home_video_banner: Block<HomeVideoBannerContentItem>
	icon_list: Block<IconListContentItem>
	image_gallery: Block<ImageGalleryContentItem>
	image: Block<ImageContentItem>
	interactive_list: Block<InteractiveListContentItem>
	jotform: Block<JotFormContentItem>
	key_info_module_columns: Block<KeyInfoModuleColumnsContentItem>
	key_info_module: Block<KeyInfoModuleContentItem>
	large_benefits_block: Block<LargeBenefitsBlockContentItem>
	link: Block<LinkContentItem>
	list_section: Block<ListSectionContentItem>
	lister_block_with_bullets: Block<ListerBlockWithBulletsContentItem>
	media_card: Block<MediaCardContentItem>
	mikmak_button: Block<MikmakButtonContentItem>
	module_article_lister: Block<ModuleArticleListerContentItem>
	module_block: Block<ModuleBlockContentItem>
	module_cdc_tab_block: Block<ModuleCdcTabBlockContentItem>
	module_coupon_lister: Block<ModuleCouponListerContentItem>
	module_dashboard_pet_cards: Block<ModuleDashboardPetCardsContentItem>
	module_medication_reminder_modal: Block<ModuleMedicationReminderModalContentItem>
	module_my_reminders: Block<ModuleMyRemindersContentItem>
	module_pet_profile_card: Block<ModulePetProfileCardContentItem>
	module_table_layout: Block<ModuleTableLayoutContentItem>
	ms_forms: Block<MsFormsContentItem>
	nadis_widget: Block
	office_location: Block<OfficeLocationContentItem>
	parasite_selector_cta_carousel: Block<ParasiteSelectorCtaCarouselContentItem>
	parasite_tracker_module: Block<ParasiteTrackerModuleContentItem>
	pet_creation_wizard: Block<PetCreationWizardContentItem>
	pet_name_form: Block<PetNameFormContentItem>
	pet_view_all_names: Block<PetViewAllNamesContentItem>
	playable_widget: Block<PlayableWidgetContentItem>
	product_directory_card: Block<ProductDirectoryCardContentItem>
	product_directory_lister: Block<ProductDirectoryListerContentItem>
	product_directory: Block<ProductDirectoryContentItem>
	product_portfolio: Block<ProductPortfolioContentItem>
	product_section_mod: Block<ProductSectionModContentItem>
	promo_module: Block<PromoModuleContentItem>
	promotion_card_group: Block<PromotionCardGroupContentItem>
	quiz: Block<QuizContentItem>
	quote_slider: Block<QuoteSliderContentItem>
	quote: Block<QuoteContentItem>
	rebate_history: Block<RebateHistoryContentItem>
	rebates_block: Block<RebatesBlockContentItem>
	rebates_table_and_filter: Block<RebatesTableAndFilterContentItem>
	resource_card: Block<ResourceCardContentItem>
	resource_card_lister: Block<ResourceCardListerContentItem>
	responsive_link_with_icon: Block<ResponsiveLinkWithIconContentItem>
	rich_card: Block<RichCardContentItem>
	rich_text_with_cta: Block<RichTextWithCTAContentItem>
	rich_text: Block<RichTextContentItem>
	row: Block<RowContentItem>
	scrolling_images: Block<ScrollingImagesContentItem>
	search_header_block: Block<SearchHeaderBlockContentItem>
	search_module: Block<SearchModuleContentItem>
	secondary_header: Block<SecondaryHeaderContentItem>
	section_header_block: Block<SectionHeaderBlockContentItem>
	share_block_static: Block<ShareBlockStaticContentItem>
	share_block: Block<ShareBlockContentItem>
	show_search_results: Block<ShowSearchResultsContentItem>
	simple_content_block_with_icon: Block<SimpleContentBlockWithIconContentItem>
	simple_lister_block: Block<SimpleListerBlockContentItem>
	small_benefits_block: Block<SmallBenefitsBlockContentItem>
	small_benefits_module: Block<SmallBenefitsModuleContentItem>
	small_text: Block<SmallTextContentItem>
	steps: Block<StepsContentItem>
	sticky_bar: Block<StickyBarContentItem>
	tabbed_block: Block<TabbedBlockContentItem>
	tabbed_content_block: Block<TabbedContentBlockContentItem>
	table_block_with_icons: Block<TableBlockWithIconsContentItem>
	table: Block<TableContentItem>
	tag_block: Block<TagBlockContentItem>
	tag_single: Block<TagSingleContentItem>
	tile_container: Block<TileContainerContentItem>
	tile_with_icon: Block<TileWithIconContentItem>
	trustcode_widget: Block<TrustcodeWidgetContentItem>
	vet_rebate_no_crm_id_found: Block<VetRebateNoCrmIdFoundContentItem>
	vet_rebate_permission: Block<VetRebatePermissionContentItem>
	video_block: Block<VideoBlockContentItem>
	video_with_rich_text: Block<VideoWithRichTextContentItem>
	wistia: Block<WistiaContentItem>
	youtube_video_header_block: Block<YoutubeVideoHeaderBlockContentItem>
}

// **Please keep keys sorted alphabetically**
const map: Record<keyof BlockMap, BlockMap[keyof BlockMap]> = {
	adoptwizard: AdoptWizardBlock,
	anchor: AnchorBlock,
	arrowhead_iframe: ArrowheadIframeBlock,
	article_header_block: ArticleHeaderBlock,
	article_lister_block_large: ArticleListerBlockLarge,
	article_lister_block: ArticleListerBlockBlock,
	article_lister: ArticleListerBlock,
	benefits_module: BenefitsModuleBlock,
	better_page_model: RichTextBlock,
	blog_heading: BlogHeadingBlock,
	brand_card: BrandTableCardBlock,
	breadcrumbs: BreadcrumbsBlock,
	buy_online_links: BuyOnlineLinksBlock,
	card_lister: CardListerBlock,
	card: CardBlock,
	carousel_cta_block: CarouselCTABlockBlock,
	cdc_gigya_popup: CdcGigyaPopupBlock,
	cdc_gigya: CdcGigyaBlock,
	cdc_screen_set_wrapper: CdcScreenSetWrapperBlock,
	click_to_buy_multi_product: ClickToBuyMultiProductBlock,
	click_to_buy_single_product: ClickToBuySingleProductBlock,
	click_to_cart: ClickToCartBlock,
	column_card_lister: ColumnCardListerBlock,
	column: ColumnBlock,
	comparison_table: ComparisonTableBlock,
	content_module_with_bullets: ContentModuleWithBulletsBlock,
	country_selector: CountrySelectorBlock,
	coupon_iframe: CouponIframeBlock,
	coupon: CouponBlock,
	cta_button_email: CTAButtonEmailBlock,
	cta_button: CtaButtonBlock,
	distributor_card_lister: DistributorCardListerBlock,
	distributor_card: DistributorCardBlock,
	dynamic_article_lister: DynamicArticleListerBlock,
	enhanced_card: EnhancedCardBlock,
	enhanced_product_card: EnhancedProductDirectoryCardBlock,
	enhanced_product_directory_card: EnhancedProductDirectoryCardBlock,
	epr_rebate_status: EprRebateStatusBlock,
	fact_lister_block: FactListerBlock,
	faq_heading_share_block: FaqHeadingShareBlockBlock,
	faq_search_header: FaqSearchHeaderBlock,
	faq_search_results: FaqSearchResultsBlock,
	faq: FaqBlock,
	featured_article: FeaturedArticleBlock,
	filter_bar: FilterBarBlock,
	filter_block: FilterBlockBlock,
	filter_side_selector: FilterSideSelectorBlock,
	find_a_business: FindABusinessBlock,
	find_your_rep: FindYourRepBlock,
	floating_component: FloatingComponentBlock,
	form_selector: FormSelectorBlock,
	full_tabbed_block: FullTabbedBlockBlock,
	full_width_carousel: FullWidthCarouselBlock,
	governance_block: GovernanceBlockBlock,
	header_block_carousel: HeaderBlockCarouselBlock,
	header_block_with_copy: HeaderBlockWithCopyBlock,
	heading_actions_wrapper: HeadingActionsWrapperBlock,
	hero_section: HeroSectionBlock,
	history_timeline: HistoryTimelineBlock,
	home_page_header_block: HomePageHeaderBlockBlock,
	home_video_banner: HomeVideoBannerBlock,
	icon_list: IconListBlock,
	image_gallery: ImageGalleryBlock,
	image: ImageBlock,
	interactive_list: InteractiveListBlock,
	jotform: JotFormBlock,
	key_info_module_columns: KeyInfoModuleColumnsBlock,
	key_info_module: KeyInfoModuleBlock,
	large_benefits_block: LargeBenefitsBlockBlock,
	link: LinkBlock,
	list_section: ListSectionBlock,
	lister_block_with_bullets: ListerBlockWithBullets,
	media_card: MediaCardBlock,
	mikmak_button: MikMakButtonBlock,
	module_article_lister: ModuleArticleListerBlock,
	module_block: ModuleBlockBlock,
	module_cdc_tab_block: ModuleCdcTabBlockBlock,
	module_coupon_lister: ModuleCouponListerBlock,
	module_dashboard_pet_cards: ModuleDashboardPetCardsBlock,
	module_medication_reminder_modal: ModuleMedicationReminderModalBlock,
	module_my_reminders: ModuleMyRemindersBlock,
	module_pet_profile_card: ModulePetProfileCardBlock,
	module_table_layout: ModuleTableLayoutBlock,
	ms_forms: MsFormsBlock,
	nadis_widget: NadisWidgetBlock,
	office_location: OfficeLocationBlock,
	parasite_selector_cta_carousel: ParasiteSelectorCtaCarouselBlock,
	parasite_tracker_module: ParasiteTrackerModuleBlock,
	pet_creation_wizard: PetCreateWizardBlock,
	pet_name_form: PetNameFormBlock,
	pet_view_all_names: PetViewAllNamesBlock,
	playable_widget: PlayableWidgetBlock,
	product_directory_card: ProductDirectoryCardBlock,
	product_directory_lister: ProductDirectoryListerBlock,
	product_directory: ProductDirectoryBlock,
	product_portfolio: ProductPortfolioBlock,
	product_section_mod: ProductSectionModBlock,
	promo_module: PromoModuleBlock,
	promotion_card_group: PromotionCardGroupBlock,
	quiz: QuizBlock,
	quote_slider: QuoteSliderBlock,
	quote: QuoteBlock,
	rebate_history: RebateHistoryBlock,
	rebates_block: RebatesBlockBlock,
	rebates_table_and_filter: RebatesTableAndFilterBlock,
	resource_card: ResourceCardBlock,
	resource_card_lister: ResourceCardListerBlock,
	responsive_link_with_icon: ResponsiveLinkWithIconBlock,
	rich_card: RichCardBlock,
	rich_text_with_cta: RichTextWithCtaBlock,
	rich_text: RichTextBlock,
	row: RowBlock,
	scrolling_images: ScrollingImagesBlock,
	search_header_block: SearchHeaderBlockBlock,
	search_module: SearchModuleBlock,
	secondary_header: SecondaryHeaderBlock,
	section_header_block: SectionHeaderBlock,
	share_block_static: ShareBlockStaticBlock,
	share_block: ShareBlockBlock,
	show_search_results: ShowSearchResultsBlock,
	simple_content_block_with_icon: SimpleContentBlockWithIconBlock,
	simple_lister_block: SimpleListerBlockBlock,
	small_benefits_block: SmallBenefitsBlockBlock,
	small_benefits_module: SmallBenefitsModuleBlock,
	small_text: SmallTextBlock,
	steps: StepsBlock,
	sticky_bar: StickyBarBlock,
	tabbed_block: TabbedBlockBlock,
	tabbed_content_block: TabbedContentBlockBlock,
	table_block_with_icons: TableBlockWithIconsBlock,
	table: TableBlock,
	tag_block: TagBlockBlock,
	tag_single: TagSingleBlock,
	tile_container: TileContainerBlock,
	tile_with_icon: TileWithIconBlock,
	trustcode_widget: TrustcodeWidgetBlock,
	vet_rebate_no_crm_id_found: VetRebateNoCrmIdFoundBlock,
	vet_rebate_permission: VetRebatePermissionBlock,
	video_block: VideoBlockBlock,
	video_with_rich_text: VideoWithRichTextBlock,
	wistia: WistiaBlock,
	youtube_video_header_block: YoutubeVideoHeaderBlockBlock,
}

const blockMap = new Map<keyof BlockMap, Block>(
	Object.entries(map) as [keyof BlockMap, Block][]
)

function mapBlocks(
	blocks: Tersed[],
	page: Tersed<AdvancedPageTemplateContentItem>,
	globalConfig: Tersed<GlobalConfigContentItem>,
	className: string,
	isComponent: boolean,
	authState: CurrentUserContext
): ReactNode {
	const components = blocks
		.filter(authState.checkUserRoleForBlock)
		.map<JSX.Element | null>((block) => {
			const {
				system: {type},
			} = block

			const key = type as keyof BlockMap
			const Component = blockMap.get(key)

			if (!Component) {
				if (env.NEXT_PUBLIC_NODE_ENV !== 'production') {
					return (
						<h2
							className="bg-red-500 p-4 text-center text-white"
							data-kontent-component-id={
								isComponent ? block.system.id : undefined
							}
							data-kontent-item-id={
								!isComponent ? block.system.id : undefined
							}
							key={block.system.id}
						>
							Unrecognized block - {block.system.type}
						</h2>
					)
				}

				return null
			}

			return (
				<div
					className={clsx(className, 'h-[inherit]')}
					data-kontent-component-id={
						isComponent ? block.system.id : undefined
					}
					data-kontent-item-id={
						!isComponent ? block.system.id : undefined
					}
					key={block.system.id}
				>
					<Component
						BlockMapper={BlockMapper}
						block={block}
						globalConfig={globalConfig}
						page={page}
					/>
				</div>
			)
		})
	return components
}

/**
 * The BlockMapper is used to take tersed data and map it into React components
 */
export function BlockMapper({
	blocks,
	page,
	globalConfig,
	className = '',
	isComponent = false,
}: {
	blocks: Tersed[]
	page: Tersed<AdvancedPageTemplateContentItem>
	globalConfig: Tersed<GlobalConfigContentItem>
	className?: string
	isComponent?: boolean
}): JSX.Element {
	const authState = useAuth()

	const components = mapBlocks(
		blocks,
		page,
		globalConfig,
		className,
		isComponent,
		authState
	)

	return <>{components}</>
}
