import {Breadcrumbs as ClBreadcrumbs} from '@elanco/component-library-v2'
import {useQuery} from 'react-query'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {fetchBreadcrumbs} from '@/fetchers/fetchBreadcrumbs'
import type {
	AdvancedPageTemplateContentItem,
	Tersed,
} from '@/_new-code/services/kontent-ai/types'

interface HeaderBreadcrumbsProps {
	page: Tersed<AdvancedPageTemplateContentItem>
}

export const HeaderBreadcrumbs = ({
	page,
}: HeaderBreadcrumbsProps): JSX.Element => {
	const pageKontentItemId = page.system.id
	const {locale = '', asPath} = useRouter()
	const {isLoading, data} = useQuery({
		queryKey: [
			'breadcrumbs',
			{
				pageId: pageKontentItemId,
				locale,
				pageUrl: asPath.split('?', 1)[0],
			},
		],
		queryFn: fetchBreadcrumbs,
		cacheTime: 15 * (60 * 1000),
		staleTime: Infinity,
	})

	if (isLoading || !Array.isArray(data)) return <div />

	return (
		<div className="border-b border-solid bg-white">
			<div className="container-wide">
				<ClBreadcrumbs>
					{data.map((crumb) => (
						<Link
							href={`/${locale}${
								crumb.url === '/' ? '' : crumb.url
							}`}
							key={crumb.id}
							className="mr-2 cursor-pointer"
						>
							{crumb.breadcrumbText || crumb.title}
						</Link>
					))}
				</ClBreadcrumbs>
			</div>
		</div>
	)
}
