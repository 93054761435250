import {useContext, useState, useEffect} from 'react'
import Link from 'next/link'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {Header, EllipseButton, ButtonIcons} from '@elanco/component-library-v2'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {GlobalContext} from '@/components/BlockMapper/GlobalClientState'

export type FaqSearchHeaderContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.TextElement
	placeholder: Elements.TextElement
	backButtonUrl: Elements.TextElement
}>

export const FaqSearchHeaderBlock: Block<FaqSearchHeaderContentItem> = ({
	block,
}) => {
	const gs = useContext(GlobalContext)
	const [searchInput, setSearchInput] = useState(
		gs?.[0]?.faqSearchQuery ?? ''
	)

	useEffect(() => {
		const params = new URL(window.location.href).searchParams
		const query = params.get('q')
		setSearchInput(query ?? '')
	}, [])

	useEffect(() => {
		// Debounce the input by waiting 500ms after user stops typing
		const timeout = setTimeout(() => {
			gs?.[1]({...gs[0], faqSearchQuery: searchInput})
		}, 500)

		return () => {
			clearTimeout(timeout)
		}
	}, [gs, searchInput])

	return (
		<Header className="text-center" noTheme>
			{/* TODO: Potential A11y Issue as we can't assume this is the only h1 on the page */}
			<h1>{block.elements.title}</h1>
			<p className="max-w-2xl pt-4">{block.elements.description}</p>
			<form
				className="relative mx-auto mt-16 w-full max-w-full md:w-[575px]"
				onSubmit={(event) => {
					event.preventDefault()
				}}
			>
				<label
					className="flex items-center"
					htmlFor="search-header-input"
				>
					<span className="sr-only">Search</span>
					<div className="-left-56 bottom-2 mt-1 pr-4 text-left lg:absolute lg:mt-0 lg:pr-0">
						<Link
							href={block.elements.backButtonUrl}
							className="inline-block lg:text-white"
						>
							<svg
								className="h-8 w-8"
								fill="none"
								stroke="currentColor"
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 19l-7-7m0 0l7-7m-7 7h18"
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
								/>
							</svg>
						</Link>
					</div>
					<input
						className="mb-0 text-black"
						id="search-header-input"
						onChange={(event) => {
							setSearchInput(event.target.value)
						}}
						placeholder={block.elements.placeholder}
						type="text"
						value={searchInput}
					/>
				</label>
				<EllipseButton
					as="button"
					className="absolute inset-y-0 right-0 my-auto mr-2"
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- Ugly Hack for CLIB
					icon={ButtonIcons.Search}
					iconSize="w-6 h-6"
					type="submit"
				>
					Search
				</EllipseButton>
			</form>
		</Header>
	)
}
