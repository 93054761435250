import type {FC} from 'react'
import Image from 'next/image'
import NextLink from 'next/link'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {TersedAsset} from '@/_new-code/services/kontent-ai/types'

interface FooterCountryFlagProps {
	countryFlagIcon?: TersedAsset[]
	countrySelectionPageUrl?: string
	countryName?: string
	isMobile: boolean
}

export const FooterCountryFlag: FC<FooterCountryFlagProps> = ({
	countryFlagIcon: CountryFlagIcon = [],
	countrySelectionPageUrl: CountrySelectionPageURL = '',
	countryName: CountryName = '',
	isMobile = false,
}) => {
	return (
		<>
			{CountryFlagIcon.length > 0 &&
			CountrySelectionPageURL !== '' &&
			CountryName !== '' ? (
				<div
					className={`${
						isMobile
							? 'flex flex-row items-center justify-center pb-4'
							: ''
					} bg-white`}
				>
					<NextLink
						href={CountrySelectionPageURL}
						locale={false}
						className="hover:text-blue-500"
					>
						{}

						<Image
							alt={CountryName}
							className="inline"
							height={28}
							loader={loadImageFromKontentAI}
							src={CountryFlagIcon[0]?.url ?? ''}
							width={28}
						/>
						<span className="ml-2 text-sm underline">
							{CountryName}
						</span>
					</NextLink>
				</div>
			) : (
				''
			)}
		</>
	)
}
