import {Button} from '@elanco/component-library-v2'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import {convertDateToLocale} from '@/utils/YPAY/commonFunctions'
import type {CtaButtonContentItem} from '@/_new-code/products/flexible-web-toolkit/blocks/cta-button'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {ModulePetCreateFormContentItem} from '../module-pet-create-form'
import {ModulePetCreateFormBlock} from '../module-pet-create-form'
import {useAdoptPetWizard} from './hooks'

export type ClinicContentItem = IContentItem<{
	name: Elements.TextElement
	crmId: Elements.TextElement
	shelterClinicDetailsAddress: Elements.TextElement
	shelterClinicDetailsCity: Elements.TextElement
	shelterClinicDetailsState: Elements.TextElement
	shelterClinicDetailsZip: Elements.TextElement
}>

export type ShelterContentItem = IContentItem<{
	name: Elements.TextElement
	crmId: Elements.TextElement
	shelterClinicDetailsAddress: Elements.TextElement
	shelterClinicDetailsCity: Elements.TextElement
	shelterClinicDetailsState: Elements.TextElement
	shelterClinicDetailsZip: Elements.TextElement
	clinics: Elements.LinkedItemsElement<ClinicContentItem>
}>

export type AdoptWizardContentItem = IContentItem<{
	bannerHeading1: Elements.TextElement
	bannerCopy1: Elements.TextElement
	formHeading1: Elements.TextElement
	formSubtext1: Elements.RichTextElement
	bannerHeading2: Elements.TextElement
	petform: Elements.LinkedItemsElement<ModulePetCreateFormContentItem>
	bannerHeading3: Elements.TextElement
	bannerCopy3: Elements.TextElement
	summaryTitle3: Elements.TextElement
	firstNameLabel: Elements.TextElement
	lastNameLabel: Elements.TextElement
	emailLabel: Elements.TextElement
	petNameLabel: Elements.TextElement
	petSpeciesLabel: Elements.TextElement
	petGenderLabel: Elements.TextElement
	petDobLabel: Elements.TextElement
	petBreedLabel: Elements.TextElement
	petWeightLabel: Elements.TextElement
	gotchaDayLabel: Elements.TextElement
	shelterNameLabel: Elements.TextElement
	vetClinicLabel: Elements.TextElement
	shelters: Elements.LinkedItemsElement<ShelterContentItem>
	errorHeading: Elements.TextElement
	errorCopy: Elements.RichTextElement
	backgroundImage: Elements.AssetsElement
	logoutButton: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

export const AdoptWizardBlock: Block<AdoptWizardContentItem> = ({
	block,
	BlockMapper,
	page,
	globalConfig,
}) => {
	const {userDetails} = useAuth()

	const {formStep, onPetCreate, newPets, handleLogout} = useAdoptPetWizard()

	let bannerTitle: string
	let bannerCopy

	switch (formStep) {
		case 3:
			bannerTitle = block.elements.bannerHeading3
			bannerCopy = block.elements.bannerCopy3.replace(
				'{{shelterName}}',
				newPets[0]?.shelterName ?? ''
			)
			break
		case 2:
			bannerTitle = block.elements.bannerHeading2
			bannerCopy = ''
			break
		case 1:
		default:
			bannerTitle = block.elements.bannerHeading1
			bannerCopy = block.elements.bannerCopy1
	}

	return (
		<div>
			<div className="container-wide relative flex flex-col md:h-[450px] md:flex-row md:justify-between">
				<div className="absolute -left-44 top-8 z-0 hidden h-[270px] w-[270px] rounded-full bg-[#CFEAC640] md:block" />
				<div className="relative z-20 mt-6 flex flex-col md:mt-20 md:max-w-xs lg:max-w-lg">
					<h1 className="mb-5 text-4xl font-semibold">
						{bannerTitle}
					</h1>
					<h2 className="text-sm font-normal">{bannerCopy}</h2>
				</div>
				{block.elements.backgroundImage[0] ? (
					<div className="flex justify-center md:absolute md:right-0">
						<div className="absolute -right-4 top-16 z-0 hidden h-[140px] w-[140px] rounded-full bg-[#258821AA]/[0.4] md:block" />
						<div className="block max-w-[180px] md:max-w-[450px]">
							<Image
								alt={
									block.elements.backgroundImage[0]
										.description
								}
								className="relative z-10"
								height={
									block.elements.backgroundImage[0].height ??
									450
								}
								loader={loadImageFromKontentAI}
								priority
								src={block.elements.backgroundImage[0].url}
								width={
									block.elements.backgroundImage[0].width ??
									450
								}
							/>
						</div>
						<div className="absolute -left-16 bottom-0 z-0 hidden h-[270px] w-[270px] rounded-full bg-[#CFEAC640] md:block" />
					</div>
				) : null}
			</div>
			<section className="container-wide relative z-20 mb-10 md:-mt-44">
				<div className="relative my-6 mt-2 max-w-xl rounded border border-light-grey bg-white p-8">
					<div className={formStep === 1 ? 'block' : 'hidden'}>
						<h3 className="mb-6 text-center font-semibold">
							{block.elements.formHeading1}
						</h3>
						<div
							className="mb-8 text-center"
							dangerouslySetInnerHTML={{
								__html: block.elements.formSubtext1.value,
							}}
						/>
						<div
							className="mx-auto max-w-sm"
							id="registration-form"
						/>
					</div>
					{formStep === 2 && (
						<div className="block">
							{block.elements.petform[0] ? (
								<ModulePetCreateFormBlock
									BlockMapper={BlockMapper}
									block={block.elements.petform[0]}
									globalConfig={globalConfig}
									hideHeading={false}
									multiplePetCreation
									onSubmit={onPetCreate}
									page={page}
									shelterData={block.elements.shelters}
								/>
							) : null}
						</div>
					)}
					<div className={formStep === 3 ? 'block' : 'hidden'}>
						<h3 className="mb-6 text-center font-semibold">
							{block.elements.summaryTitle3}
						</h3>
						<div className="py-1">
							<span className="font-bold">
								{block.elements.firstNameLabel}:
							</span>{' '}
							{userDetails.firstName}
						</div>
						<div className="py-1userDetails">
							<span className="font-bold">
								{block.elements.lastNameLabel}:
							</span>{' '}
							{userDetails.lastName}
						</div>
						<div className="mb-3 border-b py-1 pb-4">
							<span className="font-bold">
								{block.elements.emailLabel}:
							</span>{' '}
							{userDetails.emailAddress}
						</div>
						{newPets.map((pet) => (
							<div
								className={
									newPets.length > 1
										? 'mb-3 border-b pb-4 last:border-none'
										: undefined
								}
								key={pet.id ?? pet.petName}
							>
								<div className="py-1">
									<span className="font-bold">
										{block.elements.petNameLabel}:
									</span>{' '}
									{pet.petName}
								</div>
								<div className="py-1">
									<span className="font-bold">
										{block.elements.petSpeciesLabel}:
									</span>
									<span className="capitalize">
										{' '}
										{pet.petSpecies}
									</span>
								</div>
								<div className="py-1">
									<span className="font-bold">
										{block.elements.petGenderLabel}:
									</span>
									<span className="capitalize">
										{' '}
										{pet.petSex}
									</span>
								</div>
								<div className="py-1">
									<span className="font-bold">
										{block.elements.petDobLabel}:
									</span>{' '}
									{convertDateToLocale(pet.petDateOfBirth)}
								</div>
								<div className="py-1">
									<span className="font-bold">
										{block.elements.petBreedLabel}:
									</span>{' '}
									{pet.breed}
								</div>
								<div className="py-1">
									<span className="font-bold">
										{block.elements.petWeightLabel}:
									</span>{' '}
									{pet.petWeight}
								</div>
								<div className="py-1">
									<span className="font-bold">
										{block.elements.gotchaDayLabel}:
									</span>{' '}
									{convertDateToLocale(
										pet.petAcquisitionDate
									)}
								</div>
								{pet.shelterName ? (
									<div className="py-1">
										<span className="font-bold">
											{block.elements.shelterNameLabel}:
										</span>{' '}
										{pet.shelterName}
									</div>
								) : null}

								{pet.clinicName ? (
									<div className="py-1">
										<span className="font-bold">
											{block.elements.vetClinicLabel}:
										</span>{' '}
										{pet.clinicName}
									</div>
								) : null}
							</div>
						))}
						{block.elements.logoutButton[0] ? (
							<div className="pt-4 text-center">
								{block.elements.logoutButton.map((button) => (
									<Button
										className="w-full flex-1 md:max-w-[245px]"
										key={button.system.id}
										onClick={handleLogout}
										title={button.elements.text}
										variant={
											button.elements.variant[0]
												?.codename ??
											'pet_primary_green'
										}
									>
										{button.elements.text}
									</Button>
								))}
							</div>
						) : null}
					</div>
				</div>
			</section>
		</div>
	)
}
