import {useEffect, useState} from 'react'
import {useRouter} from 'next/router'
import type {PetNameSearchAPIResponse} from '@/pages/api/petNames/search'
import type {Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	PetTraitContentItem,
	PetTraitGroupContentItem,
} from '@/_new-code/products/pet-name-finder/models'
import {shuffleArray} from '@/_new-code/utilities/lists'
import type {TraitsByType} from './pet-trait-card'

// Minimum amount of time for which the modal will be shown
const MINIMUM_MODAL_MS = 2000

interface TraitsHookResult {
	activeTraitGroups: Tersed<PetTraitGroupContentItem>[]
	selectedTraitsByGroup: TraitsByType
	toggleTrait: (
		traitName: Tersed<PetTraitContentItem>,
		groupTitle: string
	) => void
	reset: () => void
	isButtonDisabled: boolean
}

export function useTraits(
	defaultTraitGroups: Tersed<PetTraitGroupContentItem>[]
): TraitsHookResult {
	const traitsByType: TraitsByType = {}
	defaultTraitGroups.forEach((group) => {
		traitsByType[group.elements.displayTitle] = null

		group.elements.traitBlocks[0]?.elements.nestedTraitGroups.forEach(
			(nestedGroup) => {
				traitsByType[nestedGroup.elements.displayTitle] = null
			}
		)
	})

	const [selectedTraitsByGroup, setSelectedTraitsByGroup] =
		useState(traitsByType)

	const toggleTrait = (
		petTrait: Tersed<PetTraitContentItem>,
		groupTitle: string
	): void => {
		if (
			selectedTraitsByGroup[groupTitle]?.elements.displayName ===
			petTrait.elements.displayName
		) {
			setSelectedTraitsByGroup((oldState) => ({
				...oldState,
				[groupTitle]: null,
			}))
		} else {
			setSelectedTraitsByGroup((oldState) => ({
				...oldState,
				[groupTitle]: petTrait,
			}))
		}
	}

	function reset(): void {
		setSelectedTraitsByGroup(traitsByType)
		document.getElementById('PetNameForm')?.scrollIntoView({
			behavior: 'smooth',
		})
	}

	const species = defaultTraitGroups[0]
	const activeTraitGroups = [...defaultTraitGroups]
	const selectedSpecie = species?.elements.traitBlocks.find(
		(trait) =>
			selectedTraitsByGroup[species.elements.displayTitle]?.elements
				.displayName === trait.elements.displayName
	)
	if (selectedSpecie) {
		activeTraitGroups.push(...selectedSpecie.elements.nestedTraitGroups)
	} else {
		const defaultSpecie = species?.elements.traitBlocks[0]
		if (defaultSpecie) {
			activeTraitGroups.push(...defaultSpecie.elements.nestedTraitGroups)
		}
	}

	const isButtonDisabled = !Object.keys(selectedTraitsByGroup).some(
		(group) => selectedTraitsByGroup[group] !== null
	)

	return {
		activeTraitGroups,
		selectedTraitsByGroup,
		toggleTrait,
		reset,
		isButtonDisabled,
	}
}

interface NameGeneratorHookResults {
	searchNames: () => Promise<void>
	shuffle: () => void
	topNames: string[]
	otherNames: string[]
	resetResults: () => void
	isLoading: boolean
}

export function useNameGenerator(
	traitsByGroup: TraitsByType,
	onNoResults: () => void
): NameGeneratorHookResults {
	const {locale: pageLocale = ''} = useRouter()
	const [allNames, setAllNames] = useState<string[]>([])
	const [unseenNames, setUnseenNames] = useState<string[]>([])
	const [topNames, setTopNames] = useState<string[]>([])
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!isLoading) {
			// eslint-disable-next-line @typescript-eslint/no-empty-function -- intentional, default is no-op
			return () => {}
		}
		const timer = setTimeout(() => {
			if (allNames.length > 0) {
				setIsLoading(false)
				document.getElementById('PetNameForm')?.scrollIntoView({
					behavior: 'smooth',
				})
			}
		}, MINIMUM_MODAL_MS)
		return () => {
			clearTimeout(timer)
		}
	}, [isLoading, allNames])

	async function searchNames(): Promise<void> {
		setIsLoading(true)

		const traitsArray: string[] = []
		Object.keys(traitsByGroup)
			.filter((group) => Boolean(traitsByGroup[group]))
			.forEach((group) => {
				traitsArray.push(
					`${group}:${
						traitsByGroup[group]?.elements.displayName ?? ''
					}`
				)
			})

		// This endpoint only exists in the petPorfolio repository
		const finalUrl = `/api/petNames/search?${new URLSearchParams({
			required: traitsArray.toString(),
			locale: pageLocale,
		}).toString()}`
		try {
			const response = await fetch(finalUrl)
			const data = (await response.json()) as PetNameSearchAPIResponse
			if (!data.data[0]) {
				onNoResults()
				return
			}
			const {names}: {names: string[]} = data.data[0]
			if (names.length === 0) {
				return
			}
			const shuffledNames = shuffleArray(names)
			const limitedNames = shuffledNames.slice(0, 25)
			setUnseenNames(limitedNames.slice(5))
			setTopNames(limitedNames.slice(0, 5))
			setAllNames(limitedNames.sort())
		} catch (e) {
			setIsLoading(false)
		}
	}

	function shuffle(): void {
		if (unseenNames.length === 0 && topNames.length > 0) {
			setTopNames([])
			setUnseenNames([])
		} else if (unseenNames.length === 0) {
			const newNames = shuffleArray(allNames)
			setTopNames(newNames.slice(0, 5))
			setUnseenNames(newNames.slice(5))
		} else {
			setTopNames(unseenNames.slice(0, 5))
			setUnseenNames((oldArray) => oldArray.slice(5))
		}

		document.getElementById('PetNameForm')?.scrollIntoView({
			behavior: 'smooth',
		})
	}

	function resetResults(): void {
		setUnseenNames([])
		setTopNames([])
		setAllNames([])
	}

	const otherNames = allNames.filter((name) => !topNames.includes(name))
	return {searchNames, shuffle, topNames, otherNames, resetResults, isLoading}
}
