/* eslint-disable @typescript-eslint/explicit-function-return-type -- Dumbest Option Ever! */
import {useContext, useState, useEffect} from 'react'
import {useRouter} from 'next/router'
import {MultipleCheckboxes} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {
	IListerFilterOption,
	IListerFilterMultipleCheckboxes,
	IGlobalState,
	TListerFilterKeys,
	TListerFilterProperties,
	TListFilters,
} from '@/models/GlobalState'
import {GlobalContext} from '@/components/BlockMapper/GlobalClientState'
import {pushToDataLayer} from '@/utils/analytics'
import {env} from '@/utils/env/client.mjs'
import {getValidSpacingOrNone} from '../../styles'
import {DesktopView} from './view-desktop'
import {MobileView} from './view-mobile'
import {filterBuilder} from './filter-builder'

export type TaxonomyContentItemKeys =
	| 'application'
	| 'brandHouse'
	| 'categories'
	| 'contentType'
	| 'disease'
	| 'issues'
	| 'lifeStage'
	| 'productAdministration'
	| 'productPlatform'
	| 'productType'
	| 'species'
	| 'subspecies'
	| 'topic'
	| 'waysToPurchase'
	| 'weightBands'

type TaxonomyContentItem = IContentItem<{
	[K in TaxonomyContentItemKeys]: Elements.TaxonomyElement
}>

export type FilterBarOptionContentItem = IContentItem<{
	allItemsTitle: Elements.TextElement
	label: Elements.TextElement
	type: Elements.MultipleChoiceElement
	taxonomy: Elements.LinkedItemsElement<TaxonomyContentItem>
}>

export type FilterBarContentItem = IContentItem<{
	title: Elements.TextElement
	resetButtonTitle: Elements.TextElement
	closeButtonTitle: Elements.TextElement
	filterBarOptions: Elements.LinkedItemsElement<FilterBarOptionContentItem>
	additionalFilterTitle: Elements.TextElement
	additionalFilterBarOptions: Elements.LinkedItemsElement<FilterBarOptionContentItem>
	listerOnPage: Elements.MultipleChoiceElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
}>

export interface FilterBarChildProps {
	additionalFilterTitle: string
	closeButtonTitle: string
	resetButtonTitle: string
	title: string
	additionalFilterBarOptions: Tersed<FilterBarOptionContentItem>[]
	filterBarOptions: Tersed<FilterBarOptionContentItem>[]
	className?: string
	globalState?: IGlobalState
	filterBarKey: TListerFilterKeys
	renderFilterOptions: (
		filterBarOption: Tersed<FilterBarOptionContentItem>,
		index: number
	) => JSX.Element | undefined
	toggleAdditionalFilters: () => void
	resetAllFilters: () => void
	setShowMobileFilters: React.Dispatch<React.SetStateAction<boolean>>
	checkShowAdditionalFilters: boolean
	showAdditionalFilters: boolean
	showMobileFilters: boolean
}

export const FilterBarBlock: Block<FilterBarContentItem> = ({block}) => {
	const globalState = useContext(GlobalContext)
	const {locale = '', ...router} = useRouter()
	const [showMobileFilters, setShowMobileFilters] = useState(false)
	const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)
	const [filterOptions, setFilterOptions] = useState({})
	const [additionalFilterOptions, setAdditionalFilterOptions] = useState({})

	const filterBarKey = `${String(
		block.elements.listerOnPage[0]?.codename
	)}FilterOptions` as TListerFilterKeys

	useEffect(() => {
		const initialFilterOptions = block.elements.filterBarOptions.reduce(
			(accumulator, currentValue) =>
				filterBuilder(accumulator, currentValue, router.asPath),
			{}
		)

		const initialAdditionalFilterOptions =
			block.elements.additionalFilterBarOptions.reduce(
				(accumulator, currentValue) =>
					filterBuilder(accumulator, currentValue, router.asPath),
				{}
			)

		setFilterOptions(initialFilterOptions)
		setAdditionalFilterOptions(initialAdditionalFilterOptions)

		globalState?.[1]({
			...globalState[0],
			[filterBarKey]: {
				...initialFilterOptions,
				...initialAdditionalFilterOptions,
			},
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Disable for easy re-use
	}, [router.asPath])

	const getUrlQueryStrings = (
		value: string,
		property: TListerFilterProperties,
		overrideExistingValue: boolean
	) => {
		const urlSearchParams = new URL(
			`${env.NEXT_PUBLIC_APP_URL}/${locale}${router.asPath}`
		).searchParams

		urlSearchParams.delete('page')
		const paramValue = urlSearchParams.get(property)

		if (!value) {
			urlSearchParams.delete(property)
			return urlSearchParams
		}

		if (overrideExistingValue || !paramValue) {
			urlSearchParams.set(property, value)
			return urlSearchParams
		}

		if (paramValue.includes(value)) {
			const paramArray = paramValue.replaceAll(value, '').split(',')

			if (paramArray.length === 1) {
				urlSearchParams.delete(property)
			} else {
				urlSearchParams.set(
					property,
					paramArray
						.filter((v) => v !== value && v.length !== 0)
						.join(',')
				)
			}
		} else {
			urlSearchParams.set(property, `${paramValue},${value}`)
		}

		return urlSearchParams
	}

	const updateFilterQuery = (
		eventValue: string,
		identifier: TListerFilterProperties,
		isDropdown = false
	) => {
		const params = getUrlQueryStrings(eventValue, identifier, isDropdown)

		const queries: Record<string, string> = {}

		params.forEach((value, key) => {
			queries[key] = value
		})

		const checkedBox = (
			value: string,
			codename: string,
			checked: boolean
		) => {
			pushToDataLayer({
				event: checked ? 'filter_clear' : 'filter_selected',
				filter_name: value,
			})
			return {value, codename, checked: !checked}
		}

		if (!isDropdown) {
			const newMultipleCheckboxData = (
				globalState?.[0][filterBarKey][
					identifier
				] as IListerFilterMultipleCheckboxes
			).checkboxOptions.map(({value, codename, checked}) =>
				eventValue === value
					? checkedBox(value, codename, checked)
					: {value, codename, checked}
			)

			const updatedGlobalState = {
				...globalState?.[0],
				[filterBarKey]: {
					...globalState?.[0][filterBarKey],
					[identifier]: {checkboxOptions: newMultipleCheckboxData},
				},
			}

			// @ts-expect-error TODO: untangle global state
			globalState?.[1](updatedGlobalState)
		}

		void router.replace(
			{
				pathname: `${locale}${String(router.asPath.split('?')[0])}`,
				query: {...queries},
			},
			undefined,
			{shallow: true}
		)
	}

	const renderFilterOptions = (
		filterBarOption: Tersed<FilterBarOptionContentItem>,
		index: number
	) => {
		const filterBarOptionType =
			filterBarOption.elements.type[0]?.codename ?? 'dropdown'
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- allowed
		const filterBarOptionTaxonomy = filterBarOption.elements.taxonomy[0]!
		const filterBarOptionTaxonomyRawKey =
			filterBarOptionTaxonomy.system.type.replace(
				'taxonomy_',
				''
			) as TListerFilterProperties
		const filterBarOptionTaxonomyKey = filterBarOptionTaxonomyRawKey
			.toLowerCase()
			.replace(
				/(?:_\w)/g,
				(match) => match[1]?.toUpperCase() ?? ''
			) as TaxonomyContentItemKeys

		if (filterBarOptionType === 'dropdown') {
			const dropdownData = [
				{text: filterBarOption.elements.allItemsTitle, value: ''},
				...filterBarOptionTaxonomy.elements[filterBarOptionTaxonomyKey]
					.map((term) => ({
						text: term.name,
						value: term.codename,
					}))
					.sort((a, b) => a.text.localeCompare(b.text)),
			]

			return (
				<div
					className={`${
						filterBarKey === 'productsFilterOptions'
							? 'xl:w-auto'
							: 'md:px-8'
					} w-full`}
					key={filterBarOption.system.id}
				>
					<label
						className="mb-2 text-[16px] font-bold text-black"
						htmlFor={filterBarOption.elements.label + String(index)}
					>
						{filterBarOption.elements.label}
					</label>
					<select
						className="xl rounded bg-white text-black"
						id={filterBarOption.elements.label + String(index)}
						onChange={(e) => {
							updateFilterQuery(
								e.target.value,
								filterBarOptionTaxonomyRawKey,
								true
							)
							pushToDataLayer({
								event: e.target.value
									? 'filter_selected'
									: 'filter_clear',
								filter_name: dropdownData.filter(
									(item) => item.value === e.target.value
								)[0]?.text,
							})
						}}
						value={
							(
								globalState?.[0][filterBarKey][
									filterBarOptionTaxonomyRawKey
								] as IListerFilterOption | undefined
							)?.value
						}
					>
						{dropdownData.map((data) => (
							<option
								key={data.value + data.text}
								value={data.value}
							>
								{data.text}
							</option>
						))}
					</select>
				</div>
			)
		}

		if (filterBarOptionType === 'multiselect') {
			// below checkBoxDataItems contains the corresponding globalState object for every taxonomy element
			const checkBoxDataItems = filterBarOptionTaxonomy.elements[
				filterBarOptionTaxonomyKey
			].map((terms) => {
				const filterOptionsInGlobalState =
					globalState?.[0][filterBarKey][
						filterBarOptionTaxonomyRawKey
					]

				if (
					filterOptionsInGlobalState &&
					'checkboxOptions' in filterOptionsInGlobalState &&
					filterOptionsInGlobalState.checkboxOptions.length > 0
				) {
					// the following block gives the object from global state that matches the current taxonomy option
					const results =
						filterOptionsInGlobalState.checkboxOptions.find(
							(gsOption) =>
								terms.name === gsOption.value &&
								terms.codename === gsOption.codename
						)

					if (results) {
						return results
					}
				}

				return {
					value: '',
					codename: '',
					checked: false,
				}
			})

			return (
				<MultipleCheckboxes
					checkboxData={checkBoxDataItems}
					checkboxesClassName="grid grid-rows-3 grid-flow-col gap-x-5 gap-y-3"
					identifier={filterBarOptionTaxonomyRawKey}
					key={filterBarOption.system.id}
					title={filterBarOption.elements.label}
					updateFilterValue={(
						value: string,
						identifier: TListerFilterProperties
					) => {
						updateFilterQuery(value, identifier)
					}}
				/>
			)
		}
	}

	const resetFilterOptions = (options: TListFilters) => {
		const resetOptions = options
		Object.keys(options).forEach((key) => {
			const filterOption = options[key as keyof TListFilters]
			if (filterOption) {
				if ('label' in filterOption) {
					resetOptions[key as keyof TListFilters] = {
						...filterOption,
						value: '',
					}
				} else {
					const resetCheckboxOptions = (
						filterOption as IListerFilterMultipleCheckboxes
					).checkboxOptions.map((item) => ({...item, checked: false}))
					resetOptions[key as keyof TListFilters] = {
						...filterOption,
						checkboxOptions: resetCheckboxOptions,
					}
				}
			}
		})
		return resetOptions
	}

	const resetAllFilters = () => {
		if (router.asPath.includes('?')) {
			void router.replace(
				locale + String(router.asPath.split('?')[0]),
				undefined,
				{
					shallow: true,
				}
			)
		}

		setFilterOptions(resetFilterOptions(filterOptions))
		setAdditionalFilterOptions(resetFilterOptions(additionalFilterOptions))

		globalState?.[1]({
			...globalState[0],
			[filterBarKey]: {...filterOptions, ...additionalFilterOptions},
		})
	}

	const toggleAdditionalFilters = () => {
		setShowAdditionalFilters(!showAdditionalFilters)
		if (!showAdditionalFilters) {
			document.body.classList.add('modal-open')
		} else {
			document.body.classList.remove('modal-open')
		}
	}

	const componentSpacing = getValidSpacingOrNone(
		block.elements.snippetSpacingSpacing[0]?.codename
	)

	const checkShowAdditionalFilters =
		block.elements.additionalFilterBarOptions.length > 0

	const filterProps: FilterBarChildProps = {
		additionalFilterBarOptions: block.elements.additionalFilterBarOptions,
		additionalFilterTitle: block.elements.additionalFilterTitle,
		closeButtonTitle: block.elements.closeButtonTitle,
		filterBarOptions: block.elements.filterBarOptions,
		resetButtonTitle: block.elements.resetButtonTitle,
		title: block.elements.title,
		checkShowAdditionalFilters,
		filterBarKey,
		globalState: globalState?.[0],
		renderFilterOptions,
		resetAllFilters,
		setShowMobileFilters,
		showAdditionalFilters,
		showMobileFilters,
		toggleAdditionalFilters,
	}

	return (
		<div className={componentSpacing}>
			<DesktopView className="hidden xl:block" {...filterProps} />
			<MobileView
				className={showAdditionalFilters ? 'xl:block' : 'xl:hidden'}
				{...filterProps}
			/>
			<hr className="mx-auto mb-8 max-w-screen-xl border-gray-200 px-3" />
		</div>
	)
}
