import type {ReactElement, SyntheticEvent} from 'react'
import {useEffect, useState} from 'react'
import FadeLoader from 'react-spinners/FadeLoader'
import {SearchResults} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {useRouter} from 'next/router'
import {env} from '@/utils/env/client.mjs'
import {pushToDataLayer} from '@/utils/analytics'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {
	ReusableCTAButtonComponent,
	type CtaButtonContentItem,
} from '../cta-button'

type PageTypeDisplayNameContentItem = IContentItem<{
	pageTypeCodename: Elements.TextElement
	displayName: Elements.TextElement
}>

export type ShowSearchResultsContentItem = IContentItem<{
	title: Elements.TextElement
	noResultsText: Elements.TextElement
	resultsText: Elements.TextElement
	label: Elements.TextElement
	linkText: Elements.TextElement
	categoryDisplayNames: Elements.LinkedItemsElement<PageTypeDisplayNameContentItem>
	loadMore: Elements.LinkedItemsElement<CtaButtonContentItem>
}>

interface Result {
	'@search.score': string
	category: string
	description: string
	id: string
	publishDate: string
	rid: string
	title: string
	url: string
}

interface MappedResult {
	category: Result['category']
	title: Result['title']
	desc: Result['description']
	url: Result['url']
}

export const ShowSearchResultsBlock: Block<ShowSearchResultsContentItem> = ({
	block,
	...context
}): ReactElement => {
	const authState = useAuth()
	const {locale = ''} = useRouter()
	const [searchResultsList, setSearchResultsList] = useState<MappedResult[]>(
		[]
	)
	const [searchTerm, setSearchTerm] = useState('')
	const [totalCount, setTotalCount] = useState(0)
	const [skipCount, setSkipCount] = useState(0)
	const [loading, setLoading] = useState(false)
	let hasNoReferrer = false
	const maxItemsVisibleCount = 18
	const searchEndpoint = context.globalConfig.elements.searchIndexUrl
	const apiKey = env.NEXT_PUBLIC_SEARCH_API_KEY

	const getDisplayName = (category: string): string => {
		const pageType = block.elements.categoryDisplayNames.find(
			(c) =>
				c.elements.pageTypeCodename.toLowerCase().trim() ===
				category.toLowerCase().trim()
		)
		return pageType?.elements.displayName ?? category
	}

	const getResults = async (): Promise<void> => {
		setLoading(true)
		const params = new URL(window.location.href).searchParams
		const searchString = params.get('q')

		const constructedUrl =
			`${searchEndpoint}${String(searchString)}&$top=${String(
				maxItemsVisibleCount
			)}&$skip=${String(skipCount)}&$count=true` +
			`&$filter=(gateLevel le ${String(authState.authLevel)})`
		setSearchTerm(searchString ?? '')
		try {
			const response = await fetch(constructedUrl, {
				method: 'GET',
				headers: {
					'api-key': apiKey,
				},
			})
			const data = (await response.json()) as {
				value: Result[] | undefined | null
				'@odata.count': number
			}
			if (data.value) {
				const mappedResults = data.value.map<MappedResult>(
					(item: Result) => {
						const localItem = item
						if (item.url && !item.url.startsWith('/')) {
							localItem.url = `/${item.url}`
						}
						const urlWithLocale = `/${locale}${localItem.url}`
						const itemCategory = getDisplayName(item.category)
						const mappedItem = {
							category: itemCategory,
							title: item.title,
							desc: item.description,
							url: urlWithLocale,
						}
						return mappedItem
					}
				)
				if (skipCount === 0) {
					pushToDataLayer({
						event: 'initiate_search_results',
						search_term: searchString,
						current_page: !hasNoReferrer
							? document.referrer || window.location.href
							: window.location.href,
					})
				}
				hasNoReferrer = true
				if (skipCount === 0) {
					pushToDataLayer({
						event: 'returned_search_results',
						search_term: searchString,
						search_results_count: data['@odata.count'],
						search_status:
							data['@odata.count'] > 0
								? 'Successful'
								: 'Unsuccessful',
					})
				}
				setSearchResultsList([...searchResultsList, ...mappedResults])
				setTotalCount(data['@odata.count'] && data['@odata.count'])
				setSkipCount((prev) => prev + maxItemsVisibleCount)
				setLoading(false)
			}
		} catch (e) {
			if (e instanceof Error) {
				// eslint-disable-next-line no-console -- exception
				console.error(
					'An error occurred when obtaining search results.',
					{
						error: e.message || e.name,
					}
				)
			} else {
				// eslint-disable-next-line no-console -- exception
				console.error(
					'An error occurred when obtaining search results.',
					{
						error: e,
					}
				)
			}

			if (skipCount === 0) {
				pushToDataLayer({
					event: 'initiate_search_results',
					search_term: searchString,
					current_page: !hasNoReferrer
						? document.referrer || window.location.href
						: window.location.href,
				})
			}
			hasNoReferrer = true
			setLoading(false)
		}
	}

	useEffect(() => {
		if (authState.authLevel >= 0) {
			void getResults()
		}

		const popStateListener = (): void => {
			void getResults()
			setSkipCount(0)
		}

		window.addEventListener('popstate', popStateListener)

		return () => {
			window.removeEventListener('popstate', popStateListener)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- Disallow for easy reuse
	}, [authState.authLevel])

	const handleClick = (e: SyntheticEvent): void => {
		e.preventDefault()
		void getResults()
	}

	const isLoadMoreVisible =
		totalCount > maxItemsVisibleCount &&
		totalCount !== searchResultsList.length
	const showResults = searchTerm !== '' || searchResultsList.length !== 0

	return (
		<div data-kontent-element-codename="show_search_results">
			{/* Conditional rendering based on search results and loading state */}
			{loading ? (
				<div className="flex w-full justify-center py-6">
					<FadeLoader
						color="#02253e"
						height={15}
						loading
						margin={2}
						radius={2}
						width={5}
					/>
				</div>
			) : null}
			{showResults && !loading ? (
				<>
					<SearchResults
						linkText={block.elements.linkText}
						newText={block.elements.label}
						noResultsText={block.elements.noResultsText}
						results={searchResultsList}
						resultsText={block.elements.resultsText || undefined}
						resultsTitle={block.elements.title || undefined}
						searchTerm={searchTerm}
						totalCount={totalCount}
					/>
					{isLoadMoreVisible ? (
						<div className="text-center">
							{block.elements.loadMore.map((button) => (
								<ReusableCTAButtonComponent
									block={button}
									key={button.system.id}
									onClick={(event: SyntheticEvent) => {
										handleClick(event)
									}}
									{...context}
								/>
							))}
						</div>
					) : null}
				</>
			) : null}
		</div>
	)
}
