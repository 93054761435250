import {RTE as RichTextComponent} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import {spacing} from '@/_new-code/products/flexible-web-toolkit/styles'
import {useAuth} from '@/_new-code/products/auth/auth-provider'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {getBrandColor} from '@/_new-code/products/flexible-web-toolkit/blocks/helpers'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'

export type RichTextWithCTAContentItem = IContentItem<{
	content: Elements.RichTextElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	tabTitle: Elements.TextElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	hideComponentOnLoginEnabled: Elements.MultipleChoiceElement
	textAlignment: Elements.MultipleChoiceElement
	tableAlignment: Elements.MultipleChoiceElement
}>

export const RichTextWithCtaBlock: Block<RichTextWithCTAContentItem> = ({
	block,
	page,
	...context
}) => {
	const pageConfig = page.elements.config[0]
	const authState = useAuth()

	const componentSpacing =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- not unnecessary, snippetSpacingSpacing may be null
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		] || spacing.none

	const color = pageConfig ? getBrandColor(pageConfig) : null

	const backgroundColor = color ? `bg-${color}` : 'bg-white'
	const buttonColor = 'bg-grey'

	const hideComponent =
		block.elements.hideComponentOnLoginEnabled[0]?.codename &&
		authState.userDetails.emailAddress !== ''

	if (hideComponent) {
		// eslint-disable-next-line react/jsx-no-useless-fragment -- TODO: change to null when typescript react types are fixed
		return <></>
	}

	return (
		<div className={clsx(backgroundColor, 'py-12', componentSpacing)}>
			<RichTextRenderer
				className={clsx(
					color ? `text-white brandtext-${color}` : '',
					'container-narrow px-8',
					'text-center',
					'content'
				)}
				data-kontent-element-codename="content"
				element={block.elements.content}
				page={page}
				{...context}
			/>
			<RichTextComponent className="text-center">
				<div className="justify-center sm:flex md:flex-row">
					{block.elements.ctaButton.map((button, index, arr) => (
						<ReusableCTAButtonComponent
							block={button}
							className={clsx(
								buttonColor,
								'mt-6 w-full max-w-xs',
								arr.length > 1 && index !== arr.length - 1
									? 'sm:mr-6'
									: ''
							)}
							key={button.system.id}
							page={page}
							variant={
								color
									? 'none'
									: block.elements.ctaButton[0]?.elements
											.variant[0]?.codename ?? 'primary'
							}
							{...context}
						/>
					))}
				</div>
			</RichTextComponent>
		</div>
	)
}
