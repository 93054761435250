import {TableBlock as Table} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import type {Block} from '@/_new-code/services/kontent-ai/types'

type TableRowDataContentItem = IContentItem<{
	title: Elements.TextElement
}>

type TableRowContentItem = IContentItem<{
	rowData: Elements.LinkedItemsElement<TableRowDataContentItem>
}>

export type TableContentItem = IContentItem<{
	tableHeader: Elements.LinkedItemsElement<TableRowContentItem>
	tableRow: Elements.LinkedItemsElement<TableRowContentItem>
	disclaimer: Elements.TextElement
}>

export const TableBlock: Block<TableContentItem> = ({block}) => {
	return (
		<>
			<Table
				table={{
					headers: [
						{
							columns: [
								...(
									block.elements.tableHeader[0]?.elements
										.rowData ?? []
								).map((d) => ({title: d.elements.title})),
							],
						},
					],
					rows: [
						...block.elements.tableRow.map((r) => ({
							data: r.elements.rowData.map((d, i) => ({
								content:
									i === 0 ? (
										<strong
											data-kontent-element-codename="title"
											data-kontent-item-id={d.system.id}
										>
											{d.elements.title}
										</strong>
									) : (
										<p
											className="whitespace-pre-line"
											data-kontent-element-codename="title"
											data-kontent-item-id={d.system.id}
										>
											{d.elements.title}
										</p>
									),
							})),
						})),
					],
				}}
			/>
			<div
				className="my-2 flex flex-row-reverse"
				data-kontent-element-codename="disclaimer"
			>
				{block.elements.disclaimer}
			</div>
		</>
	)
}
