import {useEffect, useState} from 'react'
import {Header, useWindowSize} from '@elanco/component-library-v2'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {clsx} from 'clsx'
import type {MikmakButtonContentItem} from '@/_new-code/products/where-to-buy/mikmak/mikmak-button'
import {MikMakButtonBlock} from '@/_new-code/products/where-to-buy/mikmak/mikmak-button'
import type {ClickToBuySingleProductContentItem} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToBuySingleProduct/click-to-buy-single-product'
import type {ClickToBuyMultiProductContentItem} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToBuyMultiProduct/click-to-buy-multi-product'
import type {
	Block,
	BrandConfigContentItem,
	Tersed,
	TersedAsset,
} from '@/_new-code/services/kontent-ai/types'
import {RichTextRenderer} from '@/_new-code/products/flexible-web-toolkit/components/rich-text-renderer'
import {spacing} from '../../styles'
import type {CtaButtonContentItem} from '../cta-button'
import {ReusableCTAButtonComponent} from '../cta-button'
import {ClickToBuyMultiProductBlock} from '../../../where-to-buy/smart-commerce/blocks/ClickToBuyMultiProduct'
import {ClickToBuySingleProductBlock} from '../../../where-to-buy/smart-commerce/blocks/ClickToBuySingleProduct'
import type {ClickToCartContentItem} from '../../../where-to-buy/smart-commerce/blocks/ClickToCart'
import {ClickToCartBlock} from '../../../where-to-buy/smart-commerce/blocks/ClickToCart'
import {LinkBlock, type LinkContentItem} from '../link'

export type SectionHeaderBlockContentItem = IContentItem<{
	title: Elements.TextElement
	content: Elements.RichTextElement
	logo: Elements.AssetsElement
	image: Elements.AssetsElement
	mobileImage: Elements.AssetsElement
	ctaButton: Elements.LinkedItemsElement<CtaButtonContentItem>
	clickToBuySingleProduct: Elements.LinkedItemsElement<ClickToBuySingleProductContentItem>
	clickToCart: Elements.LinkedItemsElement<ClickToCartContentItem>
	multiProductClickToBuy: Elements.LinkedItemsElement<ClickToBuyMultiProductContentItem>
	mikmakButton: Elements.LinkedItemsElement<MikmakButtonContentItem>
	textLink: Elements.LinkedItemsElement<LinkContentItem>
	fullBackgroundImage: Elements.MultipleChoiceElement
	hasWhiteText: Elements.MultipleChoiceElement
	hasWhiteBackground: Elements.MultipleChoiceElement
	mediaAlignRight: Elements.MultipleChoiceElement
	mediaLeftPosition: Elements.TextElement
	mediaTopPosition: Elements.TextElement
	packshotImage: Elements.AssetsElement
	packshotAlign: Elements.MultipleChoiceElement
	packshotRightPosition: Elements.TextElement
	packshotTopPosition: Elements.TextElement
	snippetSpacingSpacing: Elements.MultipleChoiceElement
	snippetLayoutBackgroundColorColor: Elements.MultipleChoiceElement
}>

export const SectionHeaderBlock: Block<SectionHeaderBlockContentItem> = ({
	block,
	page,
	...context
}) => {
	const pageConfig = page.elements.config[0]
	const hasFullBackground =
		block.elements.fullBackgroundImage[0]?.codename === 'yes'
	const componentSpacing =
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Necessary for type safety - codename isn't actually always of type spacing
		spacing[
			block.elements.snippetSpacingSpacing[0]
				?.codename as keyof typeof spacing
		] || spacing.none

	const brand =
		pageConfig && 'color' in pageConfig.elements
			? (pageConfig as Tersed<BrandConfigContentItem>).elements.color[0]
					?.codename
			: null

	const snippetColor =
		block.elements.snippetLayoutBackgroundColorColor[0]?.codename ?? 'none'
	const liteBackground =
		snippetColor === 'brand'
			? ` !bg-${String(brand)}-light bg-default-light-background`
			: ''

	const mediaAlignRight =
		block.elements.mediaAlignRight[0]?.codename === 'yes'

	/*
	 *	if the snippetColor doesn't equal none then convert the value from snake case to hyphen case
	 *	and also prepend it with bg- to match the tailwind classes e.g. from pet_green to bg-pet-green
	 */
	const bgSnippetColor =
		snippetColor !== 'none'
			? `bg-${snippetColor
					.replace(/(?<lowercase>[a-z])(?<uppercase>[A-Z])/g, '$1-$2')
					.replace(/[\s_]+/g, '-')}`
			: 'bg-white'

	const [headerImage, setHeaderImage] = useState<TersedAsset | null>(null)

	// eslint-disable-next-line @typescript-eslint/no-unsafe-call -- TODO: fix Component Library types
	const size = useWindowSize() as {isMobile: boolean}
	useEffect(() => {
		if (
			hasFullBackground &&
			size.isMobile &&
			block.elements.mobileImage[0]
		) {
			setHeaderImage(block.elements.mobileImage[0] || null)
		} else if (
			size.isMobile &&
			!block.elements.mobileImage.length &&
			block.elements.packshotImage.length
		) {
			setHeaderImage(null)
		} else {
			setHeaderImage(block.elements.image[0] ?? null)
		}
	}, [
		block.elements.image,
		block.elements.mobileImage,
		block.elements.packshotImage.length,
		hasFullBackground,
		size,
	])

	const backgroundBrandColor = brand ? `bg-${brand}` : 'bg-primary'
	const hasWhiteInfoContainerBg =
		block.elements.hasWhiteBackground[0]?.codename === 'yes'
	const showBuyProductsClassname =
		block.elements.clickToBuySingleProduct[0] ??
		block.elements.multiProductClickToBuy[0] ??
		block.elements.clickToCart.length > 0
			? 'flex flex-wrap'
			: ''

	return (
		<Header
			bgColor={bgSnippetColor}
			className={clsx(componentSpacing, liteBackground)}
			contain={!hasFullBackground}
			containerSize={hasFullBackground ? 'lg' : ''}
			fixedInfoWidth={!headerImage}
			fullBackgroundImage={hasFullBackground}
			hasWhiteInfoContainerBg={hasWhiteInfoContainerBg}
			logoImgAlt={block.elements.logo[0]?.description}
			logoImgSrc={block.elements.logo[0]?.url}
			noTheme
			reversedMobile
		>
			{block.elements.title ? (
				<h1
					className={clsx(
						block.elements.hasWhiteText[0]?.codename === 'yes'
							? 'text-white'
							: '',
						'break-words'
					)}
					data-kontent-element-codename="title"
				>
					{block.elements.title}
				</h1>
			) : null}
			{block.elements.content.value !== '<p><br></p>' && (
				<RichTextRenderer
					className={clsx(
						block.elements.hasWhiteText[0]?.codename === 'yes'
							? 'text-white'
							: null,
						'break-words pb-2 pt-6 *:*:!mb-0'
					)}
					data-kontent-element-codename="content"
					element={block.elements.content}
					page={page}
					{...context}
				/>
			)}
			{block.elements.ctaButton[0] ? (
				<div
					className="flex flex-wrap "
					data-kontent-element-codename="cta_button"
				>
					{block.elements.ctaButton.map((button, i) => (
						<div
							className={clsx(
								'flex',
								block.elements.ctaButton.length >= 2
									? 'w-1/2'
									: 'w-fit'
							)}
							key={button.system.id}
						>
							<div
								className={clsx(
									'mt-6 flex flex-1 flex-col justify-center',
									i === 0 ? 'mr-1' : 'ml-1'
								)}
								data-kontent-item-id={button.system.id}
							>
								<ReusableCTAButtonComponent
									block={button}
									className={clsx(
										'mb-2 h-full text-white',
										backgroundBrandColor
									)}
									page={page}
									variant={brand ?? undefined}
									{...context}
								/>
							</div>
						</div>
					))}
				</div>
			) : null}
			<div className={showBuyProductsClassname}>
				{block.elements.clickToBuySingleProduct[0] ? (
					<div className="flex w-full flex-col">
						<div
							className="flex flex-1 justify-start"
							data-kontent-item-id={
								block.elements.clickToBuySingleProduct[0].system
									.id
							}
						>
							<ClickToBuySingleProductBlock
								block={
									block.elements.clickToBuySingleProduct[0]
								}
								page={page}
								{...context}
							/>
						</div>
					</div>
				) : null}
				{block.elements.multiProductClickToBuy[0] ? (
					<div className="flex w-full flex-col">
						<div
							className="flex flex-1 justify-start"
							data-kontent-item-id={
								block.elements.multiProductClickToBuy[0].system
									.id
							}
						>
							<ClickToBuyMultiProductBlock
								block={block.elements.multiProductClickToBuy[0]}
								page={page}
								{...context}
							/>
						</div>
					</div>
				) : null}
				{block.elements.clickToCart.map((widgetBlock, i) => (
					<div
						className="flex min-h-[294px] w-1/2"
						key={widgetBlock.elements.dataSourceId}
					>
						<div
							className={clsx(
								'mt-6 flex flex-1 flex-col justify-center',
								i === 0 ? 'mr-1' : 'ml-1'
							)}
							data-kontent-item-id={widgetBlock.system.id}
						>
							<ClickToCartBlock
								block={widgetBlock}
								page={page}
								{...context}
							/>
						</div>
					</div>
				))}
			</div>
			{/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- Necessary for now, TODO: add mikmak to all towers */}
			{block.elements.mikmakButton?.[0] ? (
				<div className="flex flex-wrap">
					<div className="flex w-full flex-col">
						<div
							className="flex flex-1 justify-start"
							data-kontent-item-id={
								block.elements.mikmakButton[0].system.id
							}
						>
							<MikMakButtonBlock
								block={block.elements.mikmakButton[0]}
								page={page}
								{...context}
							/>
						</div>
					</div>
				</div>
			) : null}
			{Boolean(block.elements.textLink[0]) && (
				<div className="mt-4 flex flex-wrap">
					{block.elements.textLink.map((link, i) => (
						<div className="flex w-1/2" key={link.system.id}>
							<div
								className={clsx(
									'flex flex-1 flex-col justify-center',
									i === 0 ? 'mr-1' : 'ml-1'
								)}
							>
								<LinkBlock
									block={link}
									className={`${
										block.elements.hasWhiteText[0]
											?.codename === 'yes'
											? 'text-white'
											: ''
									} text-btn cursor-pointer text-xs underline hover:no-underline focus:text-secondary focus:no-underline focus:outline-none`}
									page={page}
									{...context}
								/>
							</div>
						</div>
					))}
				</div>
			)}
			<Header.Image
				alt={headerImage?.description}
				contain={!hasFullBackground}
				mediaAlignRight={mediaAlignRight}
				mediaLeftPosition={block.elements.mediaLeftPosition}
				mediaTopPosition={block.elements.mediaTopPosition}
				packshotImage={
					block.elements.packshotImage[0] ? (
						<Header.PackshotImage
							alt={block.elements.packshotImage[0].description}
							hasMobileImage={
								block.elements.mobileImage.length > 0
							}
							mediaAlign={
								block.elements.packshotAlign[0]?.codename ===
								'yes'
							}
							mediaRightPosition={
								block.elements.packshotRightPosition
							}
							mediaTopPosition={
								block.elements.packshotTopPosition
							}
							src={block.elements.packshotImage[0].url}
						/>
					) : null
				}
				src={headerImage?.url}
			/>
		</Header>
	)
}
