import * as formElements from './form-elements'

const simulateKeyPress = (): void => {
	const crmField = formElements.getCRMFieldElement()
	if (crmField) {
		crmField.dispatchEvent(new Event('keydown'))
		crmField.dispatchEvent(new Event('keypress'))
		crmField.dispatchEvent(new Event('keyup'))
	}
}

export const BusinessEntryModeEventListener = (event: Event): void => {
	if (!event.currentTarget) {
		throw new Error('Event has no target')
	}
	const {checked} = event.currentTarget as HTMLInputElement

	const businessSearchField = formElements.BusinessSearchField()

	if (checked) {
		const label = formElements.BusinessResultLabel()
		if (label) {
			label.innerHTML = ''
			label.style.display = 'none'
		}
		if (businessSearchField) {
			businessSearchField.disabled = true
			businessSearchField.value = ''
		}
		formElements.setCRMFieldValue('manual')
	} else {
		if (businessSearchField) {
			businessSearchField.disabled = false
		}
		formElements.setCRMFieldValue('')
	}

	formElements.setTypeFieldValue(0)
	formElements.setNameFieldValue('')
	formElements.setAddress1FieldValue('')
	formElements.setAddress2FieldValue('')
	formElements.setCityFieldValue('')
	formElements.setStateFieldValue(0)
	formElements.setZipFieldValue('')

	simulateKeyPress()
}
