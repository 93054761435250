import dynamic from 'next/dynamic'
import type {Block} from '@/_new-code/services/kontent-ai/types'
import type {ClickToBuySingleProductContentItem} from '@/_new-code/products/where-to-buy/smart-commerce/blocks/ClickToBuySingleProduct/click-to-buy-single-product'

const ClickToBuySingleProductComponent = dynamic(
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Necessary to fix build
	// @ts-ignore -- Necessary to fix build
	() =>
		import('./click-to-buy-single-product').then(
			(mod) => mod.ClickToBuySingleComponent
		),
	{
		ssr: false,
	}
)

export const ClickToBuySingleProductBlock: Block<
	ClickToBuySingleProductContentItem
> = (props) => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- Necessary to fix build
	// @ts-ignore -- Necessary to fix build
	return <ClickToBuySingleProductComponent {...props} />
}
