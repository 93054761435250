import type {AxiosResponse} from 'axios'
import axios from 'axios'
import type {UseQueryResult} from 'react-query'
import {useQuery} from 'react-query'
import {env} from '@/utils/env/client.mjs'

interface TrustcodeRequest {
	code: string
	agent: string
	geoLoc?: {
		lng: number
		lat: number
	}
	deviceId: string
}

interface TrustcodeCommon {
	status: 'VALID' | 'DEACTIVATED' | 'UNKNOWN'
	links: {
		aggregateUrl: string
	}
	checkId: string
	codeId: string
	counterfeited: boolean
	country: string
	repositoryId: string
	repositoryKey: string
	showWeatherData: boolean
	tenantId: string
	trustCodeId: string
}

type TrustcodeResponse = TrustcodeCommon

interface TrustcodeResponseAggregate {
	differentDevicesCheckCount: number
	individualProductData: {
		code: {
			checkCount: number
			code: string
			codeLength: number
			status: string
		}
		codeRepositoryRef: string
		created: number
		customData: unknown
		id: string
		individualProduct: string
		lastModified: number
	}
	selfUrl: string
}

type Trustcode =
	| ({
			status: 'VALID'
			aggregateData: TrustcodeResponseAggregate
	  } & TrustcodeCommon)
	| ({
			status: 'DEACTIVATED' | 'UNKNOWN'
	  } & TrustcodeCommon)

const getTrustcodeValidState = async (
	code: string,
	deviceId: string
): Promise<Trustcode> => {
	const url = `https://${
		env.NEXT_PUBLIC_TRUSTCODE_HOST ?? 'undefined'
	}/codemgmt.codecheck.server/rest/v2/check/${
		env.NEXT_PUBLIC_TRUSTCODE_REPO_KEY ?? 'undefined'
	}`
	const request: TrustcodeRequest = {
		agent: navigator.userAgent,
		code,
		deviceId,
	}

	if ('geolocation' in navigator) {
		try {
			const position: GeolocationPosition =
				await new Promise<GeolocationPosition>((resolve, reject) => {
					navigator.geolocation.getCurrentPosition(resolve, reject)
				})
			const {latitude: lat, longitude: lng} = position.coords
			request.geoLoc = {lat, lng}
		} catch (error) {
			// eslint-disable-next-line no-console -- fine for now
			console.error('Unable to fetch Geolocation Data')
		}
	}

	const {data} = await axios.post<
		TrustcodeResponse,
		AxiosResponse<TrustcodeResponse>,
		TrustcodeRequest
	>(url, request)

	const {status} = data

	if (status === 'VALID') {
		const {data: aggregateData} =
			await axios.get<TrustcodeResponseAggregate>(data.links.aggregateUrl)

		return {...data, aggregateData}
	}

	return {...data, status}
}

export const useTrustcode = (
	code: string,
	deviceId: string
): UseQueryResult<Trustcode> =>
	useQuery('trustcode', () => getTrustcodeValidState(code, deviceId), {
		// In the event of failure, retry 3 times
		retry: 3,
		// Once we have the data, don't re-fetch it
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		staleTime: 1000 * 60 * 60 * 24,
	})
