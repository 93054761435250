import {Icon, SimpleContentBlock} from '@elanco/component-library-v2'
import Image from 'next/image'
import type {Elements, IContentItem} from '@kontent-ai/delivery-sdk'
import {loadImageFromKontentAI} from '@/imageLoader'
import type {Block, Tersed} from '@/_new-code/services/kontent-ai/types'
import type {IconContentItem} from '../icon'
import {RichTextRenderer} from '../../components/rich-text-renderer'

export type SimpleContentBlockWithIconContentItem = IContentItem<{
	title: Elements.TextElement
	description: Elements.RichTextElement
	image: Elements.AssetsElement
	iconHeading: Elements.TextElement
	icons: Elements.LinkedItemsElement<IconContentItem>
	borderColor: Elements.MultipleChoiceElement
}>

const SimpleContentBlockIcon = ({
	icon,
}: {
	icon: Tersed<IconContentItem>
}): JSX.Element => {
	return (
		<div data-kontent-item-id={icon.system.id}>
			<Icon
				className="mr-2"
				size={icon.elements.size[0]?.codename ?? 'sm'}
			>
				<div className="relative h-full w-full">
					{icon.elements.icon[0] ? (
						<Image
							alt={icon.elements.icon[0].description || ''}
							data-kontent-element-codename="icon"
							fill
							loader={loadImageFromKontentAI}
							src={icon.elements.icon[0].url}
						/>
					) : null}
				</div>
			</Icon>
		</div>
	)
}

export const SimpleContentBlockWithIconBlock: Block<
	SimpleContentBlockWithIconContentItem
> = ({block, ...context}) => {
	return (
		<SimpleContentBlock
			className={`p-4 md:p-8 ${
				(block.elements.borderColor[0]?.codename ?? 'none') === 'gray'
					? 'border border-gray-500'
					: ''
			}`}
			contentFlexLayoutOnDesktop
			description={
				<>
					<RichTextRenderer
						className="mb-4"
						element={block.elements.description}
						{...context}
					/>
					<div className="flex flex-wrap items-center">
						{block.elements.iconHeading ? (
							<span className="mr-4 font-bold">
								{block.elements.iconHeading}
							</span>
						) : null}
						<div className="my-2 flex">
							{block.elements.icons.map((icon) => (
								<SimpleContentBlockIcon
									icon={icon}
									key={icon.system.id}
								/>
							))}
						</div>
					</div>
				</>
			}
			iconSvg={
				block.elements.image[0] ? (
					<Image
						alt={block.elements.image[0].description || ''}
						height={block.elements.image[0].height ?? 107}
						loader={loadImageFromKontentAI}
						src={block.elements.image[0].url}
						width={block.elements.image[0].width ?? 107}
					/>
				) : null
			}
			isLargeIconSvg
			title={block.elements.title}
		/>
	)
}
